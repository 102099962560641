import { FormLabel, HStack, NumberInput, NumberInputField, Text, VStack } from "@chakra-ui/react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useState } from "react";
import { Property } from "../../lib/api/types/Property";
import formatAddress from "../../lib/utils/formatAddress";
import { AddressSelect } from "../address-select/AddressSelect";
import { LatLng } from "../property-map/data/map-types";

interface GeoLocatorProps {
  property: Property;
  position: LatLng;
  setPosition: (position: LatLng) => void;
  defaultZoom?: number;
}

export default function GeoLocator({ property, position, defaultZoom = 10, setPosition }: GeoLocatorProps) {
  const { lat, lng } = position;
  const [addressError, setAddressError] = useState<boolean>(false);
  const [addressValue, setAddressValue] = useState<string>(formatAddress(property));
  const [mapCenter, setMapCenter] = useState({ lat, lng });

  return (
    <VStack spacing={10}>
      <HStack>
        <NumberInput
          max={80}
          min={-80}
          value={lat}
          onChange={(value) => {
            const newPos = { lat: parseFloat(value) || 0, lng };
            setPosition(newPos);
            setMapCenter(newPos);
          }}
        >
          <FormLabel color="gray.400" fontSize="sm">
            Latitude:
          </FormLabel>
          <NumberInputField />
        </NumberInput>
        <NumberInput
          value={lng}
          onChange={(value) => {
            const newPos = { lat, lng: parseFloat(value) || 0 };
            setPosition(newPos);
            setMapCenter(newPos);
          }}
          min={-180}
          max={180}
        >
          <FormLabel color="gray.400" fontSize="sm">
            Longitude:
          </FormLabel>
          <NumberInputField />
        </NumberInput>
      </HStack>

      <VStack w="100%" alignItems="stretch">
        <Text>Search address location</Text>
        <AddressSelect
          value={addressValue}
          onChange={setAddressValue}
          onSelectedAddress={(address) => {
            if (!address.latitude || !address.longitude) {
              setAddressError(true);
              setTimeout(() => setAddressError(false), 3000);
            } else {
              const newPos = { lat: address.latitude || 0, lng: address.longitude || 0 };
              setPosition(newPos);
              setMapCenter(newPos);
            }
          }}
        />
        {addressError && <Text color="red.500">Location data missing for selected address</Text>}
      </VStack>

      <VStack borderWidth="1px" borderColor="gray.50" borderRadius="md" p={5}>
        <Text>Drag the map marker to update the location</Text>

        <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_KEY || ""}>
          <Map
            center={mapCenter}
            defaultZoom={defaultZoom}
            zoomControl={true}
            style={{ width: "300px", height: "300px" }}
            streetViewControl={false}
            fullscreenControl={false}
            mapTypeControl={false}
            onCenterChanged={(e) => {
              if (e.detail.center) setMapCenter(e.detail.center);
            }}
          >
            <Marker
              position={{ lat, lng }}
              draggable
              onDrag={({ latLng }) => {
                if (!!latLng) {
                  setPosition({ lat: latLng.lat(), lng: latLng.lng() });
                }
              }}
            />
          </Map>
        </APIProvider>
      </VStack>
    </VStack>
  );
}
