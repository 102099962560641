import { Box, Flex, HStack, Link, Text, Tooltip, VStack } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { OrganisationEnergyReadings } from "../../lib/api/types/EnergyInsights";
import { PropertyInfo } from "../../lib/api/types/PropertyInfo";
import { PropertyIcon } from "../create-property/property-icons/PropertyIcon";
import { PropertyMapPropertyData } from "../property-map/data/PropertyMapContext";
import UnitDisplay from "../unit-display/UnitDisplay";
import { PortfolioInsight, PortfolioInsightIcons, PortfolioInsightLabels } from "./data/types";
import React, { useMemo } from "react";
import NextLink from "next/link";
import formatAddress from "../../lib/utils/formatAddress";
import _ from "lodash";
import { HealthScore } from "../overview-tiles/HealthScore";
import MouldIndexChart from "../mould-risk-chart/MouldRiskChart";
import { getMouldRiskStatusText } from "../overview-tiles/tiles/MouldIndexTile";
import ProductivityChart from "../productivity-chart/ProductivityChart";
import { comparePropertiesForInsightSort } from "../property-map/data/sort";
import { DataTableContainer } from "../table/DataTableContainer";
import { OrganisationInsights } from "../../lib/api/types/Insights";
import { Property } from "../../lib/api/types/Property";
import { CircuitNodeWithUsage } from "../../lib/api/types/CircuitNode";
import EnergyTagSelector from "./EnergyTagSelector";
import { getEnergyPerTag, useGetTagData } from "./data/useGetTagData";
import { Loader } from "../loading/Loader";
import InfoIconWithTooltip from "../info-tooltip-with-tooltip/InfoIconWithTooltip";
import { OrganisationTag } from "../../lib/api/types/OrganisationTag";
import AirborneIndexChart from "../airborne-index-chart";
import { ComfortScoreChart } from "../comfort-score-chart/ComfortScoreChart";

interface DashboardPropertyListProps {
  selectedInsight: PortfolioInsight;
  properties: Property[];
  environmentalInsights?: OrganisationInsights;
  energyInsights?: OrganisationEnergyReadings;
  circuitTrees?: CircuitNodeWithUsage[];
  selectedTag?: string;
  onTagSelected: (tag?: string) => void;
}

export default function DashboardPropertyList({
  selectedInsight,
  properties,
  environmentalInsights,
  energyInsights,
  circuitTrees,
  selectedTag,
  onTagSelected,
}: DashboardPropertyListProps) {
  const { energyPerTag, isLoading: tagsLoading, error: tagsError } = useGetTagData(circuitTrees);

  const data = useMemo(() => {
    const selectedOrgTag = energyPerTag.find((t) => t.label === selectedTag);

    return properties.map((property) => {
      const propertyEnergyInsights = {
        ...energyInsights?.properties.find((p) => p.id === property.id)?.insights,
      };
      if (selectedOrgTag) {
        propertyEnergyInsights.totalEnergyUsageKwh = getEnergyReadingForProperty(
          property,
          selectedOrgTag,
          circuitTrees
        );
      }

      return {
        ...property,
        energyInsights: propertyEnergyInsights,
        environmentalInsights: environmentalInsights?.properties.find((p) => p.propertyId === property.id)
          ?.insights,
      };
    });
  }, [properties, environmentalInsights, energyInsights, selectedTag, circuitTrees, energyPerTag]);

  const tags = (energyPerTag.map((t) => t?.label).filter((t) => !!t) as string[]).sort();

  return (
    <Flex
      flexDir="column"
      align="stretch"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="md"
      w="100%"
    >
      <HStack spacing={4} justify="space-between" p={5}>
        <Text fontSize={14} fontWeight="bold">
          Properties
        </Text>
        {tagsLoading && <Loader />}
        {selectedInsight === PortfolioInsight.Energy && !tagsLoading && !tagsError && tags.length > 1 && (
          <HStack>
            <Text fontSize={14} fontWeight="bold">
              View As:
            </Text>
            <InfoIconWithTooltip tooltip="This will filter the energy readings for each property to show only that measured at circuit locations with this tag" />

            <Box borderRadius="md" bgColor="gray.50" p={1} px={0.5}>
              <EnergyTagSelector tags={tags} selectedTag={selectedTag} setSelectedTag={onTagSelected} />
            </Box>
          </HStack>
        )}
      </HStack>
      <Box mt={-10} w="100%">
        <DataTableContainer
          key={`dashboard-property-list-${selectedInsight}`} // Put a key on it so that it resorts when changing insight
          data={data}
          columns={makeDashboardPropertyListColumns({ insight: selectedInsight })}
          showFilter={false}
          pageTitle=""
          defaultSort={{ accessor: "energyInsights", desc: true }}
        />
      </Box>
    </Flex>
  );
}

function makeDashboardPropertyListColumns({ insight }: { insight: PortfolioInsight }) {
  const columnHelper = createColumnHelper<PropertyMapPropertyData>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnDef<PropertyMapPropertyData, any>[] = [
    columnHelper.accessor("type", {
      header: () => <Text fontSize={12}>TYPE</Text>,
      cell: ({ row }) => {
        return (
          <Tooltip label={_.startCase(row.original.type)}>
            <PropertyIcon size={34} type={row.original.type} />
          </Tooltip>
        );
      },
    }),

    columnHelper.accessor("address1", {
      header: () => <Text fontSize={12}>ADDRESS</Text>,
      cell: ({ row }) => {
        return (
          <VStack overflow="hidden" alignItems="flex-start" spacing={0.5}>
            {!!row.original.customPropertyId && (
              <NextLink href={`/property/${row.original.id}`} passHref legacyBehavior>
                <Link>{row.original.customPropertyId}</Link>
              </NextLink>
            )}
            <NextLink href={`/property/${row.original.id}`} passHref legacyBehavior>
              <Link>{formatAddress(row.original)}</Link>
            </NextLink>
          </VStack>
        );
      },
      sortingFn: (a, b) => {
        const aValue = `${a.original.customPropertyId}${
          a.original.customPropertyId ? " " : ""
        }${formatAddress(a.original)}`;
        const bValue = `${b.original.customPropertyId}${
          b.original.customPropertyId ? " " : ""
        }${formatAddress(b.original)}`;
        return aValue.localeCompare(bValue);
      },
    }),
    columnHelper.accessor("energyInsights", {
      header: () => <Flex>{PortfolioInsightLabels[insight]}</Flex>,
      cell: ({ row }) => {
        const { environmentalInsights, energyInsights } = row.original;
        switch (insight) {
          case PortfolioInsight.Energy:
            return (
              <>
                <UnitDisplay value={energyInsights?.totalEnergyUsageKwh} unit=" kWh" emptyState="No data" />
              </>
            );
          case PortfolioInsight.HealthScore:
            return (
              <>
                <VStack>
                  <HealthScore
                    healthScore={environmentalInsights?.healthScore}
                    rowCount={1}
                    size="sm"
                    columnGap="5px"
                  />
                </VStack>
              </>
            );
          case PortfolioInsight.MouldRisk:
            return (
              <>
                <VStack w="100%">
                  <Text fontWeight="400 !important" whiteSpace="nowrap">
                    {getMouldRiskStatusText(false, environmentalInsights?.mouldRiskPercentages, "12px")}
                  </Text>
                  <MouldIndexChart
                    type="linear"
                    mouldRiskPercentages={environmentalInsights?.mouldRiskPercentages}
                  />
                </VStack>
              </>
            );
          case PortfolioInsight.Productivity:
            return (
              <VStack w="100%" minWidth={200}>
                <ProductivityChart
                  productivityPercentage={environmentalInsights?.productivityPercentage}
                  type="linear"
                />
              </VStack>
            );
          case PortfolioInsight.AirborneIndex:
            return (
              <AirborneIndexChart
                type="linear"
                airborneIndexPercentages={environmentalInsights?.airborneIndexPercentages}
              />
            );
          case PortfolioInsight.Comfort:
            return (
              <ComfortScoreChart
                type="linear"
                comfortIndexPercentages={environmentalInsights?.comfortIndexPercentages}
              />
            );

          default:
            return <Text>No data</Text>;
        }
      },
      sortingFn: (a, b) => comparePropertiesForInsightSort(a.original, b.original, "desc", insight),
    }),
  ];

  return columns;
}

const getEnergyReadingForProperty = (
  property: PropertyMapPropertyData,
  tag?: OrganisationTag,
  circuitTrees?: CircuitNodeWithUsage[]
) => {
  if (tag) {
    // Filter the energy readings to only those with the selected tag
    if (!circuitTrees) {
      return;
    }

    const propertyCircuitTrees = circuitTrees.filter((c) => c.propertyId === property.id);
    const energyPerTag = getEnergyPerTag(propertyCircuitTrees, [tag]);
    return energyPerTag[0]?.totalkWh;
  }

  return property.energyInsights?.totalEnergyUsageKwh;
};
