import { useMemo } from "react";
import { useGetOrganisationTags } from "../../../lib/api/properties/hooks";
import { CircuitNodeWithUsage } from "../../../lib/api/types/CircuitNode";
import { OrganisationTag } from "../../../lib/api/types/OrganisationTag";

export const useGetTagData = (nodeTrees: CircuitNodeWithUsage[] = []) => {
  const { organisationTags = [], isLoading, error } = useGetOrganisationTags();

  const energyPerTag = useMemo(() => getEnergyPerTag(nodeTrees, organisationTags), [
    nodeTrees,
    organisationTags,
  ]);

  return {
    energyPerTag,
    isLoading,
    error,
  };
};

export const getEnergyPerTag = (nodeTrees: CircuitNodeWithUsage[], organisationTags: OrganisationTag[]) => {
  if (!organisationTags.length) {
    return [];
  }

  const tagReadings: Record<string, number[]> = {};

  const getReadingsFromNode = (nodeTree: CircuitNodeWithUsage) => {
    (nodeTree.tagIds || []).forEach((tagId) => {
      tagReadings[tagId] = tagReadings[tagId] || [];
      tagReadings[tagId].push(nodeTree.usage?.totalkWh || 0);
    });

    if (nodeTree.children?.length) {
      nodeTree.children.forEach((child) => getReadingsFromNode(child));
    }
  };

  nodeTrees.forEach((nodeTree) => {
    getReadingsFromNode(nodeTree);
  });

  const tagData = Object.keys(tagReadings)
    .map((tagId) => {
      const total = tagReadings[tagId].reduce((acc, val) => acc + val, 0);
      const tag = organisationTags.find((tag) => tag.id === tagId);
      if (total) {
        return {
          ...tag,
          totalkWh: total,
          readings: tagReadings[tagId],
        };
      }
    })
    .filter((t) => !!t && !!t.label);

  return tagData as (OrganisationTag & { totalkWh: number; readings: number[] })[];
};
