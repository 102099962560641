import { StarIcon } from "@chakra-ui/icons";
import { BsLightningChargeFill, BsWind } from "react-icons/bs";
import { FaVirus } from "react-icons/fa";
import { GiMushrooms } from "react-icons/gi";
import BrainIcon from "../../icons/BrainIcon";

export enum PortfolioInsight {
  Energy = "energy",
  HealthScore = "health-score",
  MouldRisk = "mould-risk",
  Productivity = "productivity",
  Comfort = "comfort",
  AirborneIndex = "airborne-index",
}

export const PortfolioInsightLabels: Record<PortfolioInsight, string> = {
  [PortfolioInsight.Energy]: "Energy",
  [PortfolioInsight.HealthScore]: "Health Score",
  [PortfolioInsight.MouldRisk]: "Mould Risk",
  [PortfolioInsight.Productivity]: "Productivity",
  [PortfolioInsight.Comfort]: "Comfort",
  [PortfolioInsight.AirborneIndex]: "Airborne Index",
};

export const PortfolioInsightIcons: Record<PortfolioInsight, JSX.Element> = {
  [PortfolioInsight.Energy]: <BsLightningChargeFill fill="#F2A63D" />,
  [PortfolioInsight.HealthScore]: < StarIcon fill={"#FFCE1F"} />,
  [PortfolioInsight.MouldRisk]: <GiMushrooms fill="#FB5169" />,
  [PortfolioInsight.Productivity]: <BrainIcon fill="#EA6BA6" size={20} />,
  [PortfolioInsight.Comfort]: <BsWind fill="#4499FF" />,
  [PortfolioInsight.AirborneIndex]: <FaVirus size={20} fill={"#7133C3"} />,
};