import { useQuery, UseQueryOptions } from "react-query";
import fetchWithTimeout from "../../utils/fetchWithTimeout";

const url = `https://api.ipfind.com/me?auth=${process.env.NEXT_PUBLIC_IP_FIND_API_KEY}`;

interface IPFindResult {
  latitude: number;
  longitude: number;
}

const getLocation = async function () {
  const result = await fetchWithTimeout(url);

  if (result.status !== 200) {
    throw new Error("Failed to fetch location");
  }

  const json = await result.json();

  return json as IPFindResult;
};

export default function useIPFind(options?: UseQueryOptions<IPFindResult, Error>) {
  return useQuery<IPFindResult, Error>("ipfind-location", getLocation, { retry: false, ...options });
}
