import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface PortfolioCardProps {
  title: string;
  children: ReactNode;
}

export default function PortfolioCard({ title, children }: PortfolioCardProps) {
  return (
    <Flex flexDir="column" bg="white" boxShadow={"md"} borderRadius="md" borderColor={"gray.50"} borderWidth={1} my={4} w="100%" h="100%">
      <Text m={4}>{title}</Text>
      <Divider color={"gray.50"} width="100%" />
      <Flex flexDir="column" alignItems="center" justifyContent="center" flex={1} overflow="hidden">
      {children}
      </Flex>
    </Flex >
  );
}