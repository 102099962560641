import { startCase } from "lodash";
import { ToggleButtons } from "../button/ToggleButtons";

interface EnergyTagSelectorProps {
  tags: string[];
  selectedTag?: string;
  setSelectedTag: (tag?: string) => void;
}

export default function EnergyTagSelector({ tags, selectedTag, setSelectedTag }: EnergyTagSelectorProps) {
  console.log({ selectedTag });
  return (
    <ToggleButtons<string>
      width={65}
      height={8}
      background="white"
      buttons={tags.map((tag) => ({
        label: startCase(tag),
        value: tag,
      }))}
      selectedValue={selectedTag}
      onSelect={(value: string) => setSelectedTag(value)}
      onDeselect={() => setSelectedTag(undefined)}
      boxShadow="md"
      mx={0.5}
    />
  );
}
