import { Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import useDebounce from "../../lib/utils/useDebounce";
import AutoCompleteInput, { AutoCompleteInputItem } from "../autocomplete-input/AutoCompleteInput";
import { getAddyAddress, lookupAddySuggestions } from "./addy";
import { SelectedAddress } from "./types";

interface AddressSelectProps {
  value: string;
  onChange: (value: string) => void;
  onSelectedAddress: (a: SelectedAddress) => void;
  onIsProcessingAddressChanged?: (processing: boolean) => void;
}

const resolveAddyAddress = async (
  id: string | number,
  selectedAddressText: string
): Promise<SelectedAddress> => {
  try {
    const addyAddress = await getAddyAddress(id);
    const selectedAddress = {
      address1: addyAddress.address1,
      address2: addyAddress.suburb,
      addressCity: addyAddress.city || addyAddress.mailtown,
      addressCountry: addyAddress.country,
      addressPostcode: addyAddress.postcode,
      latitude: Number(addyAddress.y),
      longitude: Number(addyAddress.x),
    };
    return selectedAddress;
  } catch (e) {
    // 20 Landing Road, Titirangi, Auckland 0604
    const [address1, suburb, city, postCode] = selectedAddressText.split(",");
    const selectedAddress = {
      address1: address1,
      address2: suburb,
      addressCity: city,
      addressPostcode: postCode,
    };
    return selectedAddress;
  }
};

export const AddressSelect = ({
  value,
  onChange,
  onSelectedAddress,
  onIsProcessingAddressChanged,
}: AddressSelectProps) => {
  const [isFetchingSelectedAddress, setIsFetchingSelectedAddress] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(value, 200);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const findAddresses = async ({ queryKey: [_, term] }: any) => {
    const suggestions = await lookupAddySuggestions(term);
    setHasSearched(true);
    return suggestions.map<AutoCompleteInputItem>((s) => ({
      value: s.id.toString(),
      label: s.a,
    }));
  };

  const { data: items = [], isLoading } = useQuery<AutoCompleteInputItem[]>(
    ["addressSearch", debouncedSearchTerm],
    findAddresses,
    {
      // only fetch search terms longer than 2 characters
      enabled: debouncedSearchTerm?.length > 2,
    }
  );

  const handleSelectedItem = async (item: AutoCompleteInputItem) => {
    setIsFetchingSelectedAddress(true);

    if (onIsProcessingAddressChanged) {
      onIsProcessingAddressChanged(true);
    }

    const selectedAddress = await resolveAddyAddress(item.value, item.label);
    setIsFetchingSelectedAddress(false);

    if (onIsProcessingAddressChanged) {
      onIsProcessingAddressChanged(false);
    }

    onSelectedAddress(selectedAddress);
  };

  return (
    <AutoCompleteInput
      placeholder="Enter Address"
      shouldFilter={false}
      onChange={onChange}
      items={items}
      value={value}
      isLoading={isLoading || isFetchingSelectedAddress}
      onSelectedItem={handleSelectedItem}
      menuHeader={
        <>
          {Boolean(hasSearched && !isLoading && debouncedSearchTerm?.length > 2 && !items.length) && (
            <Text p={2} m={2}>
              No address matches found
            </Text>
          )}
        </>
      }
    />
  );
};
