import { ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, FlexProps, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { Property } from "../../lib/api/types/Property";
import formatAddress from "../../lib/utils/formatAddress";
import SingleLineText from "../text/SingleLineText";

interface PropertyAddressDisplayProps extends Omit<FlexProps, "property"> {
  property: Property;
  isLink?: boolean;
  showFullOnHover?: boolean;
}

export default function PropertyAddressDisplay({ property, isLink, ...props }: PropertyAddressDisplayProps) {
  if (isLink) {
    return (
      <NextLink href={`/property/${property.id}`} passHref legacyBehavior>
        <PropertyAddressDisplayInner property={property} isLink={isLink} {...props} />
      </NextLink>
    );
  }

  return <PropertyAddressDisplayInner property={property} isLink={isLink} {...props} />;
}

const PropertyAddressDisplayInner = ({
  property,
  isLink,
  showFullOnHover,
  ...props
}: PropertyAddressDisplayProps) => {
  return (
    <Flex flexDir="row" alignItems="center" w="100%" minW={0} cursor={isLink ? "pointer" : "default"}>
      <Flex
        alignItems="flex-start"
        flexDir="column"
        w="100%"
        minW={0}
        _hover={{ textDecoration: isLink ? "underline" : "none" }}
        {...props}
      >
        <Text fontWeight={"bold"}>{property.customPropertyId}</Text>
        <SingleLineText showFullOnHover={showFullOnHover} openDelay={500}>
          {formatAddress(property)}
        </SingleLineText>
      </Flex>
      {!!isLink && <ChevronRightIcon fontSize={20} mt={-0.5} />}
    </Flex>
  );
};
