import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { BiHelpCircle } from "react-icons/bi";

interface InfoIconWithTooltipProps {
  tooltip: string;
  fontSize?: string | number;
  color?: string;
}
const InfoIconWithTooltip = ({ tooltip, fontSize, color }: InfoIconWithTooltipProps) => {
  return (
    <Tooltip label={tooltip}>
      <QuestionOutlineIcon fontSize={fontSize || 14} color={color || "black"} />
    </Tooltip>
  );
};

export default InfoIconWithTooltip;
