import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Kbd } from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { debounce } from "lodash";

export const SearchFilter = ({
  globalFiltertext,
  setGlobalFilterText,
  filterPlaceholderText = "Search...",
  showShortcutIcon = false,
}: {
  globalFiltertext: string;
  setGlobalFilterText: (globalFilterText: string) => void;
  filterPlaceholderText?: string;
  showShortcutIcon?: boolean;
}) => {
  const [value, setValue] = useState(globalFiltertext);
  const onChange = debounce((value) => {
    setGlobalFilterText(value || undefined);
  }, 100);
  const searchRef = useRef<HTMLInputElement>(null);

  useHotkeys("/", (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    searchRef.current?.focus();
  });

  const onReset = useCallback(() => {
    setValue("");
    onChange("");
  }, [setValue, onChange]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape" && onReset) {
        onReset();
      }
    },
    [onReset]
  );

  return (
    <InputGroup size="md" bg="white" borderRadius="md">
      <InputLeftElement>
        <Search2Icon color="#595F6A" />
      </InputLeftElement>
      <Input
        ref={searchRef}
        placeholder={filterPlaceholderText}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onKeyDown={onKeyDown}
        type={"text"}
      />
      <InputRightElement>
        {value ? (
          <IconButton
            onClick={onReset}
            size="xs"
            variant="ghost"
            aria-label="Reset search"
            icon={<CloseIcon />}
          />
        ) : (
          <>
            {showShortcutIcon && (
              <Kbd mr={2} bg="none" fontSize="lg" fontWeight="bold">
                /
              </Kbd>
            )}
          </>
        )}
      </InputRightElement>
    </InputGroup>
  );
};
