import { PartialWithRequired } from "../../utils/PartialWithRequired";
import { Property } from "./Property";

export enum DayOfWeek {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export const Weekdays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
];
export const Weekends = [DayOfWeek.Saturday, DayOfWeek.Sunday];

export interface MonitoringHours {
  organisationId: string;
  scopeId: string;
  scopeType: MonitoringHoursScopeType;
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
  timezone: string;
}

export type EditedMonitoringHours = PartialWithRequired<
  MonitoringHours,
  "startTime" | "endTime" | "dayOfWeek"
>;

export enum MonitoringHoursScopeType {
  Organisation = "organisation",
  Property = "property",
  Alarm = "alarm",
}

export interface PropertyWithMonitoringHours extends Property {
  monitoringHours: MonitoringHours[];
}

export interface OrganisationMonitoringHours {
  monitoringHours: MonitoringHours[];
  properties: PropertyWithMonitoringHours[];
}
