import { Box, Flex, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { PropertyIcon } from "../../create-property/property-icons/PropertyIcon";
import MouldIndexChart from "../../mould-risk-chart/MouldRiskChart";
import { HealthScore } from "../../overview-tiles/HealthScore";
import { getMouldRiskStatusText } from "../../overview-tiles/tiles/MouldIndexTile";
import UnitDisplay from "../../unit-display/UnitDisplay";
import {
  PropertyMapEvent,
  PropertyMapEventSource,
  PropertyMapPropertyData,
} from "../data/PropertyMapContext";
import usePropertyMapContext from "../data/usePropertyMapContext";
import { PortfolioInsight } from "../../portfolio-dashboard/data/types";
import { lighten, darken } from "@chakra-ui/theme-tools";
import theme from "../../global-styles/theme";
import { TbCurrentLocationOff } from "react-icons/tb";
import { checkPropertyCoordinates } from "../PropertyMap";
import { useEffect, useRef } from "react";
import ProductivityChart from "../../productivity-chart/ProductivityChart";
import AirborneIndexChart from "../../airborne-index-chart";
import { getAirborneIndexStatusText } from "../../overview-tiles/tiles/AirborneIndexTile";
import PropertyAddressDisplay from "../../property/PropertyAddressDisplay";
import { ComfortIndexCategory } from "../../../lib/api/types/Insights";
import { ComfortScoreChart } from "../../comfort-score-chart/ComfortScoreChart";
import MissingLocationIcon from "./MissingLocationIcon";

interface PropertyMapSidebarCardProps {
  property: PropertyMapPropertyData;
  selectedInsight: PortfolioInsight;
}

export default function PropertyMapSidebarCard(props: PropertyMapSidebarCardProps) {
  const { property } = props;
  const {
    hoverProperty,
    unhoverProperty,
    selectProperty,
    deselectProperty,
    subscribe,
    unsubscribe,
  } = usePropertyMapContext();
  const cardRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (property.isActive) {
      deselectProperty(property.id);
    } else {
      selectProperty(property.id, PropertyMapEventSource.Sidebar);
    }
  };

  // If the property is selected on the sidebar, scroll to it on the sidebar
  useEffect(() => {
    const handler = (propertyId: string) => {
      if (propertyId === property.id) {
        cardRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    subscribe(PropertyMapEvent.SelectedOnMap, handler);
    return () => unsubscribe(PropertyMapEvent.SelectedOnMap, handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef]);

  const highlightColor = property.highlightColor || "gray.200";

  let borderColor = "gray.50";
  if (property.isHovered) {
    borderColor = lighten(highlightColor, 20)(theme);
  }
  if (property.isActive) {
    borderColor = darken(highlightColor, 20)(theme);
  }

  return (
    <Flex
      ref={cardRef}
      flexDir={"column"}
      bg="white"
      borderRadius="lg"
      p={4}
      minW={0}
      w="100%"
      boxShadow="md"
      border="1px solid"
      borderColor={borderColor}
      onClick={handleClick}
      onMouseEnter={() => hoverProperty(property.id)}
      onMouseLeave={() => unhoverProperty(property.id)}
      _hover={{
        bg: "gray.50",
      }}
      position="relative"
      cursor="pointer"
      mb={1}
    >
      <Flex mb={2} maxW="100%" alignItems="center" minW={0}>
        <Box minWidth={10}>
          <PropertyIcon size={19} type={property.type} />
        </Box>
        <PropertyAddressDisplay isLink={property.isActive} property={property} showFullOnHover />
        {/* Icon to represent if the property is not shown on the map due to missing lat/long */}
        {!checkPropertyCoordinates(property) && <MissingLocationIcon property={property} />}
      </Flex>
      <Box>
        <InsightDisplay {...props} />
      </Box>
    </Flex>
  );
}

export const InsightDisplay = (props: PropertyMapSidebarCardProps) => {
  return (
    <VStack spacing={2} alignItems="stretch">
      <HStack>
        <InsightDisplayInner {...props} />
      </HStack>
    </VStack>
  );
};

const InsightDisplayInner = ({
  selectedInsight,
  property,
}: Omit<PropertyMapSidebarCardProps, "children">) => {
  const { environmentalInsights, energyInsights } = property;

  switch (selectedInsight) {
    case PortfolioInsight.Energy:
      return (
        <>
          <Text>Energy Usage:</Text>
          <UnitDisplay value={energyInsights?.totalEnergyUsageKwh} unit=" kWh" emptyState="--" />
        </>
      );
    case PortfolioInsight.HealthScore:
      return (
        <>
          <VStack>
            <HStack>
              <Text>Health Score:</Text>
              <UnitDisplay value={environmentalInsights?.healthScore} emptyState="--" decimalPlaces={1} />
            </HStack>
            <HealthScore
              healthScore={environmentalInsights?.healthScore}
              rowCount={1}
              size="sm"
              columnGap="5px"
            />
          </VStack>
        </>
      );
    case PortfolioInsight.MouldRisk:
      return (
        <>
          <VStack w="100%">
            {!!environmentalInsights?.mouldRiskPercentages && (
              <Text fontWeight="400 !important">
                {getMouldRiskStatusText(false, environmentalInsights?.mouldRiskPercentages, "12px", false)}
              </Text>
            )}
            {!environmentalInsights?.mouldRiskPercentages && <Text color="gray.400">No data</Text>}
            <MouldIndexChart
              type="linear"
              mouldRiskPercentages={environmentalInsights?.mouldRiskPercentages}
            />
          </VStack>
        </>
      );
    case PortfolioInsight.Productivity:
      return (
        <VStack w="100%">
          <HStack>
            <Text>Productive Period: </Text>
            <UnitDisplay
              value={
                environmentalInsights?.productivityPercentage !== undefined
                  ? environmentalInsights.productivityPercentage * 100
                  : undefined
              }
              unit="%"
              emptyState="--"
              color={property.highlightColor ? darken(property.highlightColor, 20)(theme) : "black"}
            />
          </HStack>
          <ProductivityChart
            productivityPercentage={environmentalInsights?.productivityPercentage}
            type="linear"
          />
        </VStack>
      );
    case PortfolioInsight.AirborneIndex:
      return (
        <VStack w="100%">
          {!!environmentalInsights?.airborneIndexPercentages && (
            <Text textAlign="center">
              {getAirborneIndexStatusText(
                environmentalInsights?.airborneIndexPercentages?.high,
                environmentalInsights?.airborneIndexPercentages?.mid
              )}
              {environmentalInsights?.airborneIndexPercentages === undefined && "No data"}
            </Text>
          )}
          {!environmentalInsights?.airborneIndexPercentages && (
            <Text textAlign="center" color="gray.400">
              No data
            </Text>
          )}
          <AirborneIndexChart
            type="linear"
            airborneIndexPercentages={environmentalInsights?.airborneIndexPercentages}
          />
        </VStack>
      );
    case PortfolioInsight.Comfort:
      return (
        <VStack w="100%">
          <HStack>
            <Text>Perfect Comfort: </Text>
            <UnitDisplay
              value={
                (environmentalInsights?.comfortIndexPercentages || {})[ComfortIndexCategory.perfectComfort]
                  ? ((environmentalInsights?.comfortIndexPercentages || {})[
                      ComfortIndexCategory.perfectComfort
                    ] || 0) * 100
                  : undefined
              }
              unit="%"
              color={
                (environmentalInsights?.comfortIndexPercentages || {})[ComfortIndexCategory.perfectComfort]
                  ? "black"
                  : "gray.200"
              }
              fontWeight="bold"
              emptyState="--"
            />
          </HStack>
          <ComfortScoreChart
            comfortIndexPercentages={environmentalInsights?.comfortIndexPercentages}
            type="linear"
          />
        </VStack>
      );
    default:
      return null;
  }
};
