import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td, chakra, useColorModeValue as mode, Tfoot } from "@chakra-ui/react";
import { flexRender, Table as TableInstance } from "@tanstack/react-table";

interface DataTableProps {
  dt: TableInstance<any>;
  hasColoredHeader?: boolean;
  hasBorderShadow?: boolean;
  showFooter?: boolean;
}

export const DataTable = ({
  dt,
  showFooter,
  hasBorderShadow = true,
  hasColoredHeader = false,
}: DataTableProps) => {
  const { getHeaderGroups, getRowModel, getFooterGroups } = dt;

  const headerColor = hasColoredHeader ? "gray.50" : "white";
  const tHeadBg = mode("blue.100", "gray.50");

  return (
    <Table boxShadow={hasBorderShadow ? "lg" : ""}>
      <Thead bg={tHeadBg} fontSize={12}>
        {getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th
                fontSize={12}
                borderTopColor={headerColor}
                borderBottomColor={headerColor}
                borderWidth={1}
                borderColor={headerColor}
                color="black"
                position="sticky"
                top={0}
                zIndex="banner"
                key={header.id}
                bg={headerColor}
                whiteSpace="nowrap"
              >
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <chakra.span
                      pl="4"
                      position="absolute"
                      right={5}
                      top={2.5}
                      key={`${header.id}-sort-icon`}
                    >
                      {
                        {
                          asc: <TriangleUpIcon aria-label="sorted ascending" />,
                          desc: <TriangleDownIcon aria-label="sorted descending" />,
                        }[header.column.getIsSorted() as string]
                      }
                    </chakra.span>
                  </div>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody bg={mode("white", "gray.50")}>
        {getRowModel().rows.map((row) => {
          return (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td borderBottomColor="gray.50" borderBottomWidth={1} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
      {showFooter && (
        <Tfoot>
          {getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </Tfoot>
      )}
    </Table>
  );
};
