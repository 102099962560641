import { AirborneIndexPercentages, MouldRiskPercentages } from "../../../lib/api/types/Insights";
import formatAddress from "../../../lib/utils/formatAddress";
import { PortfolioInsight } from "../../portfolio-dashboard/data/types";
import { PropertyMapPropertyData } from "./PropertyMapContext";

const calculateWeightedMouldScore = (risk?: MouldRiskPercentages) => {
  return (risk?.veryHigh ?? 0) * 4 + (risk?.high ?? 0) * 2 + (risk?.mid ?? 0) * 1;
};

const calculateMouldRiskCompare = (
  a: MouldRiskPercentages | undefined,
  b: MouldRiskPercentages | undefined,
  sortDirection: "asc" | "desc"
) => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return sortDirection === "asc" ? 1 : -1;
  }
  if (b === undefined) {
    return sortDirection === "asc" ? -1 : 1;
  }

  let diff = 0;

  // Sort by risk zone first
  diff = calculateWeightedMouldScore(a) - calculateWeightedMouldScore(b);
  if (diff !== 0) return diff;

  // If the risk zones are the same, sort by the low risk
  diff = (a?.low ?? 0) - (b?.low ?? 0);
  return diff;
};

const calculateWeightedAirborneScore = (risk?: AirborneIndexPercentages) => {
  return (risk?.high ?? 0) * 3 + (risk?.mid ?? 0) * 1;
};

const calculateAirborneCompare = (
  a: AirborneIndexPercentages | undefined,
  b: AirborneIndexPercentages | undefined,
  sortDirection: "asc" | "desc"
) => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return sortDirection === "asc" ? 1 : -1;
  }
  if (b === undefined) {
    return sortDirection === "asc" ? -1 : 1;
  }

  let diff = 0;

  // Sort by risk zone first
  diff = calculateWeightedAirborneScore(a) - calculateWeightedAirborneScore(b);
  if (diff !== 0) return diff;

  return diff;
};

// Sorts two number values and forces properties without insights to the bottom
const compareNumberValues = (
  a: number | undefined | null,
  b: number | undefined | null,
  sortDirection: "asc" | "desc"
) => {
  if (a == null && b == null) {
    return 0;
  }

  // Force properties without insights to the bottom of the list regardless of sort direction
  if (a == null) {
    return sortDirection === "asc" ? 1 : -1;
  }
  if (b == null) {
    return sortDirection === "asc" ? -1 : 1;
  }

  return a - b;
};

export const comparePropertiesForInsightSort = (
  a: PropertyMapPropertyData,
  b: PropertyMapPropertyData,
  sortDirection: "asc" | "desc",
  insight: PortfolioInsight
) => {
  let comparison = 0;

  switch (insight) {
    case PortfolioInsight.Energy:
      comparison = compareNumberValues(
        a.energyInsights?.totalEnergyUsageKwh,
        b.energyInsights?.totalEnergyUsageKwh,
        sortDirection
      );
      break;
    case PortfolioInsight.HealthScore:
      comparison = compareNumberValues(
        a.environmentalInsights?.healthScore,
        b.environmentalInsights?.healthScore,
        sortDirection
      );
      break;
    case PortfolioInsight.MouldRisk:
      comparison = calculateMouldRiskCompare(
        a.environmentalInsights?.mouldRiskPercentages,
        b.environmentalInsights?.mouldRiskPercentages,
        sortDirection
      );
      break;
    case PortfolioInsight.Productivity:
      comparison = compareNumberValues(
        a.environmentalInsights?.productivityPercentage,
        b.environmentalInsights?.productivityPercentage,
        sortDirection
      );
      break;
    case PortfolioInsight.AirborneIndex:
      comparison = calculateAirborneCompare(
        a.environmentalInsights?.airborneIndexPercentages,
        b.environmentalInsights?.airborneIndexPercentages,
        sortDirection
      );
      break;
    case PortfolioInsight.Comfort:
      comparison = compareNumberValues(
        a.environmentalInsights?.comfortIndexPercentages?.perfectComfort,
        b.environmentalInsights?.comfortIndexPercentages?.perfectComfort,
        sortDirection
      );
      break;
    default:
  }

  if (comparison === 0) {
    // Fallback to sort alphabetically by address
    const addressA = formatAddress(a);
    const addressB = formatAddress(b);

    comparison = addressA.localeCompare(addressB);
  }

  return comparison;
};

export const sortProperties = (
  properties: PropertyMapPropertyData[],
  sortDirection: "asc" | "desc",
  insight: PortfolioInsight
) => {
  return properties.sort((a, b) =>
    sortDirection === "asc"
      ? comparePropertiesForInsightSort(a, b, sortDirection, insight)
      : comparePropertiesForInsightSort(b, a, sortDirection, insight)
  );
};
