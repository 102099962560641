import { Box, Text, TextProps, Tooltip } from "@chakra-ui/react";
import { useState } from "react";

interface SingleLineTextProps extends TextProps {
  showFullOnHover?: boolean;
  openDelay?: number;
}

export default function SingleLineText({ showFullOnHover, openDelay, ...textProps }: SingleLineTextProps) {
  return (
    <Tooltip label={showFullOnHover ? textProps.children : ""} hasArrow openDelay={openDelay}>
      <Text maxW="100%" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" {...textProps} />
    </Tooltip>
  );
}
