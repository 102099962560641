import { Box, Center, CloseButton, HStack, Image, Text } from "@chakra-ui/react";
import React from "react";

interface NewUserModalProps {
  onClose: () => void;
}

export const NewUserModal = ({ onClose }: NewUserModalProps) => {
  return (
    <>
      <Center>
        <Box
          borderRadius="md"
          w={"801px"}
          display={"flex"}
          flexDirection="row"
          m={4}
          p={4}
          bg={"white"}
          justifyContent={"center"}
        >
          <HStack flex={1} mr={8}>
            <Box p={4} flex={1}>
              <Text fontSize="2xl" fontWeight="extrabold">
                Insights on the go!
              </Text>
              <Text>
                Download our app to install your devices and get access to your buildings&apos; data, insights
                and alerts from your pocket.
              </Text>
              <HStack mt={4}>
                <Image
                  alt="app store icon"
                  src="/assets/app-download-buttons 1@2x.png"
                  height={31}
                  width={94}
                />
                <Image
                  alt="playstore icon"
                  src="/assets/google-playstore.png"
                  style={{ height: 46, width: 100 }}
                />
              </HStack>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
              bg="gray.50"
              borderRadius={8}
              p={2}
              flexWrap="wrap"
            >
              <Image alt="qr" src="/assets/qr.png" height={113} width={113} />
            </Box>
          </HStack>
          <Image
            position={"relative"}
            bottom={-4}
            right={0}
            alt="mobile app"
            src={"/assets/mobile-overview@2x.png"}
            height={159}
            width={134}
          />
          <Box position={"relative"} top={-2} right={-2}>
            <CloseButton onClick={onClose} color={"black"} variant={"ghost"} />
          </Box>
        </Box>
      </Center>
    </>
  );
};
