import fetchWithTimeout from "../../utils/fetchWithTimeout";

const ADDY_API_KEY = "aff28de449684eb492e50bd4a4655046";

export interface AddressDetail {
  id: number;
  dpid: number;
  linzid: number;
  address1: string;
  address2: string;
  city: string;
  country: string;
  x: string;
  y: string;
  postcode: string;
  suburb: string;
  mailtown: string;
}

export interface AddySuggestion {
  a: string;
  id: number | string;
}

export const lookupAddySuggestions = (address: string): Promise<AddySuggestion[]> => {
  const url = `https://api.addy.co.nz/search?s=${encodeURIComponent(address)}&key=${ADDY_API_KEY}`;
  return fetch(url)
    .then((res) => res.json())
    .then((r) => r.addresses);
};

export const getAddyAddress = (addressId: string | number): Promise<AddressDetail> => {
  const url = `https://api.addy.co.nz/address/${addressId}?&key=${ADDY_API_KEY}`;
  return fetchWithTimeout(url, { timeout: 3000 })
    .then((res) => res.json())
    .then((res) => {
      return { ...res, country: "New Zealand" };
    });
};
