export enum AirborneRisk {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export enum Statistic {
  TEMPERATURE = "temperature",
  HUMIDITY = "humidity",
  CO2 = "co2",
  DEWPOINT = "dewPoint",
  COVIDCARE = "covidCare",
  PM1 = "pm1",
  PM2_5 = "pm2_5",
  PM10 = "pm10",
}

interface LocationStatisticsData {
  min: number;
  max: number;
  average: number;
  riskPercentages: Record<AirborneRisk, number>;
}

export interface LocationStatisticsGroup {
  overview: LocationStatisticsData;
  morning: LocationStatisticsData;
  afternoon: LocationStatisticsData;
  evening: LocationStatisticsData;
  night: LocationStatisticsData;
}

export type StatisticsData = Record<Statistic, LocationStatisticsGroup>;

export interface InstallationLocationStatistics {
  fromTimestamp: string;
  toTimestamp: string;
  installationLocationId: string;
  installationLocationName: string;
  statistics: StatisticsData;
  readings: { [key: string]: [number, number][] };
}

export interface RoomLocationStatistics {
  fromTimestamp: string;
  toTimestamp: string;
  roomLocation: string;
  roomLocationName: string;
  statistics: StatisticsData;
  readings: { [key: string]: [number, number][] };
}
