import { Flex, Text, TextProps } from "@chakra-ui/react";

interface UnitDisplayProps extends TextProps {
  prefix?: string;
  unit?: string;
  value?: number;
  decimalPlaces?: number;
  emptyState?: string;
  spacing?: number;
}

export default function UnitDisplay({
  prefix,
  unit,
  value,
  decimalPlaces = 2,
  emptyState,
  spacing = 0,
  ...textProps
}: UnitDisplayProps) {
  if (value == null) {
    return (
      <Flex>
        <Text>{emptyState}</Text>
      </Flex>
    );
  }

  return (
    <Flex columnGap={spacing}>
      {Boolean(prefix) && <Text {...textProps}>{prefix}</Text>}
      <Text {...textProps}>{value.toFixed(decimalPlaces)}</Text>
      {Boolean(unit) && <Text {...textProps}>{unit}</Text>}
    </Flex>
  );
}
