import { startCase } from "lodash";
import { DayOfWeek, MonitoringHours } from "../api/types/MonitoringHours";

export function getMonitoringHoursDisplayText(monitoringHours: MonitoringHours[] | undefined) {
  if (!monitoringHours?.length) {
    return "Not configured";
  }

  const includesWeekend = monitoringHours.some((d) => d.dayOfWeek === "saturday");

  return `${monitoringHours[0]?.startTime} - ${monitoringHours[0]?.endTime} (${
    includesWeekend ? "including" : "excluding"
  } weekends)`;
}

export function getOutsideMonitoringHoursDisplayText(monitoringHours: MonitoringHours[] | undefined) {
  if (monitoringHours === undefined) {
    return "Not configured";
  }
  if (monitoringHours.length === 0) {
    return "Not configured";
  }

  const includesWeekend = monitoringHours.some((d) => d.dayOfWeek === "saturday");
  const weekendText = includesWeekend ? "including weekends" : "and all weekend";

  const insideStart = monitoringHours[0].startTime;
  const insideEnd = monitoringHours[0].endTime;

  // Define the start and end times for outside monitoring
  const outsideStart = "00:00";
  const outsideEnd = "23:59";

  return `${outsideStart} - ${insideStart} and ${insideEnd} - ${outsideEnd} (${weekendText})`;
}

export const getCustomMonitoringHoursSummaryString = (monitoringHours: MonitoringHours[]) => {
  const days = Object.values(DayOfWeek);

  let result = "";

  let start: MonitoringHours | null = null;
  let last: MonitoringHours | null = null;

  for (const day of days) {
    const today = monitoringHours.find((mh) => mh.dayOfWeek === day);

    if (!today) {
      if (start) {
        if (last) {
          result +=
            (result ? ", " : "") +
            `${startCase(start.dayOfWeek)} - ${startCase(last.dayOfWeek)} ${start.startTime} - ${
              last.endTime
            }`;
        } else {
          result +=
            (result ? ", " : "") + `${startCase(start.dayOfWeek)} ${start.startTime} - ${start.endTime}`;
        }
        start = null;
        last = null;
      }
      continue;
    }

    if (!start) {
      start = today;
      continue;
    }

    if (today.startTime !== start?.startTime || today.endTime !== start?.endTime) {
      if (start) {
        if (last) {
          result +=
            (result ? ", " : "") +
            `${startCase(start.dayOfWeek)} - ${startCase(last.dayOfWeek)} ${start.startTime} - ${
              last.endTime
            }`;
        } else {
          result +=
            (result ? ", " : "") + `${startCase(start.dayOfWeek)} ${start.startTime} - ${start.endTime}`;
        }
        start = today;
        last = null;
      }
      continue;
    }

    last = today;
  }

  if (start != null) {
    if (last) {
      result +=
        (result ? ", " : "") +
        `${startCase(start.dayOfWeek)} - ${startCase(last.dayOfWeek)} ${start.startTime} - ${last.endTime}`;
    } else {
      result += (result ? ", " : "") + `${startCase(start.dayOfWeek)} ${start.startTime} - ${start.endTime}`;
    }
  }

  return result;
};
