import { Box, Flex, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { CircuitNodeWithUsage } from "../../lib/api/types/CircuitNode";
import { getColor, GraphColor } from "../../lib/api/types/Room";
import InfoIconWithTooltip from "../info-tooltip-with-tooltip/InfoIconWithTooltip";
import { Loader } from "../loading/Loader";
import { StatsPieChart } from "../overview-tiles/tiles/StatsPieChart";
import { useGetTagData } from "./data/useGetTagData";
import PortfolioCard from "./PortfolioCard";

interface AssetTagEnergyTileProps {
  nodeTrees?: CircuitNodeWithUsage[];
}

export default function AssetTagEnergyTile({ nodeTrees }: AssetTagEnergyTileProps) {
  const { energyPerTag, isLoading, error } = useGetTagData(nodeTrees);
  const pieData = energyPerTag.map((tag) => ({
    title: tag?.label || "",
    value: Math.round(tag?.totalkWh || 0),
    color: getColor(tag?.color as GraphColor)?.color || "gray",
    key: tag?.id,
  }));

  return (
    <PortfolioCard title="Asset Tags">
      {isLoading && <Loader />}
      {!isLoading && error && <InfoText>Error loading data</InfoText>}
      {!isLoading && !error && !energyPerTag.length && (
        <InfoText>
          No data for tagged circuits &nbsp;
          <InfoIconWithTooltip
            tooltip="Adding tags to your circuit locations allows you to easily compare them between properties"
            color="gray.400"
          />
        </InfoText>
      )}
      {!isLoading && !error && energyPerTag.length > 0 && (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          my={4}
          flexWrap={{
            base: "wrap", // 0px
            lg: "nowrap", // ~992px
          }}
        >
          <Box h="120px" minW="140px" overflow="hidden">
            <StatsPieChart graphData={pieData} mode="proportion" unit=" kWh" />
          </Box>
          <Flex gap={4} flexWrap="wrap" px={2} justifyContent="center">
            {energyPerTag.map((tag) =>
              tag && tag.totalkWh ? (
                <AssetTagKey
                  key={`asset-key-${tag.id}`}
                  label={tag.label || ""}
                  value={Math.round(tag.totalkWh)}
                  color={getColor(tag.color as GraphColor)?.color}
                />
              ) : null
            )}
          </Flex>
        </Flex>
      )}
    </PortfolioCard>
  );
}

const InfoText = ({ children }: { children: ReactNode }) => {
  return (
    <Box h="100%" p={5}>
      <Text color={"gray.400"} textAlign="center">
        {children}
      </Text>
    </Box>
  );
};

const AssetTagKey = ({ label, value, color = "gray" }: { label: string; value: number; color?: string }) => {
  return (
    <HStack alignItems={"baseline"}>
      <Box height={"9px"} width={"9px"} borderRadius="full" bgColor={color} />
      <Box>
        <Text fontWeight={"bold"}>{label}</Text>
        <Text color={"gray.700"}>{value}kWh</Text>
      </Box>
    </HStack>
  );
};
