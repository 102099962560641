import { Box, Center, CenterProps, VStack } from "@chakra-ui/layout";
import { Progress } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { useEffect, useState } from "react";

export const ProgressLoader = ({
  size,
  value,
  max,
  ...props
}: { size?: string; value: number; max: number } & CenterProps) => {
  // This is used to keep the progress bar moving slowly in between updates to show that the app is still working
  const [ticker, setTicker] = useState(0);

  useEffect(() => {
    // Reset the ticker when the value changes
    setTicker(0);
  }, [value]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTicker((ticker) => ticker + 1);
    }, 200);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Center flex="1" {...props}>
      <VStack>
        <Box>
          <Spinner size={size} />
        </Box>
        {/* The "30 + " is to make the progress bar look fuller so people don't think they're waiting for ever when calls are actually loading simultaneously */}
        <Progress
          size="xs"
          value={30 + value * 30 + (ticker < 30 ? ticker : 29)}
          max={30 + max * 30}
          colorScheme="cyan"
          width={100}
          h={0.5}
        />
      </VStack>
    </Center>
  );
};
