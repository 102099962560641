import React, { useState } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { LatLng, MapPOI } from "./data/map-types";
import { calculateMapInitialZoom } from "./data/helpers";

interface MapWithMarkersProps {
  points: MapPOI[];
  center: LatLng;
}

const MapWithMarkers: React.FC<MapWithMarkersProps> = ({ points, center }) => {
  const [zoom] = useState<number>(calculateMapInitialZoom(points));

  return (
    <Map
      defaultCenter={center}
      defaultZoom={zoom}
      zoomControl={true}
      mapId={process.env.NEXT_PUBLIC_PROPERTY_DASHBOARD_MAP_ID || ""}
      style={{ width: "100%", height: "100%" }}
    >
      {points.map((point) => point.markerComponent)}
    </Map>
  );
};

export default MapWithMarkers;
