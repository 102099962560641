import { CircuitReading } from "../../components/property-graph/GraphUtils";
import { Circuit, CircuitWithReading } from "../api/types/Circuit";
import { CircuitNode, CircuitNodeWithUsage } from "../api/types/CircuitNode";

export interface FlattenedNodeWithBreadcrumbs {
  id: string;
  breadcrumb: string;
  node: CircuitNode | CircuitNodeWithUsage;
}

export const flattenCircuitNodesWithBreadcrumbs = (
  circuitNodeTree: CircuitNode[] | CircuitNodeWithUsage[] | undefined,
  ignoreNodeId?: string
) => {
  if (!circuitNodeTree) return [];
  const flattenedNodes: FlattenedNodeWithBreadcrumbs[] = [];

  const flattenNode = (node: CircuitNode, parentName?: string) => {
    flattenedNodes.push({
      id: node.id,
      breadcrumb: parentName ? parentName + " -> " + node.name : node.name,
      node: node,
    });
    if (node.children) {
      node.children
        .filter((node) => {
          if (ignoreNodeId) return node.id !== ignoreNodeId;
          return true;
        })
        .map((childNode) => {
          flattenNode(childNode, node.name);
        });
    }
  };
  circuitNodeTree
    ?.filter((node) => {
      if (ignoreNodeId) return node.id !== ignoreNodeId;
      return true;
    })
    .map((node) => {
      flattenNode(node);
    });

  return flattenedNodes;
};

export const sortCircuits: (a: Circuit | CircuitReading, b: Circuit | CircuitReading) => number = (a, b) => {
  if (a.phase === null) {
    return -1;
  }
  if (b.phase === null) {
    return 1;
  }
  if (a.phase && b.phase) {
    return a.phase - b.phase;
  }
  return 0;
};
