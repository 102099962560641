import { Flex } from "@chakra-ui/react";
import React from "react";
import { PropertyIcon } from "../../create-property/property-icons/PropertyIcon";
import { PropertyMapEventSource, PropertyMapPropertyData } from "../data/PropertyMapContext";
import usePropertyMapContext from "../data/usePropertyMapContext";
import { InsightDisplay } from "../sidebar/PropertyMapSidebarCard";
import MapMarker from "./MapMarker";
import PropertyAddressDisplay from "../../property/PropertyAddressDisplay";
import { PortfolioInsight } from "../../portfolio-dashboard/data/types";

interface ProperyMapMarkerProps {
  property: PropertyMapPropertyData;
}

export default function PropertyMapMarker({ property }: ProperyMapMarkerProps) {
  const {
    insight,
    selectProperty,
    deselectProperty,
    hoverProperty,
    unhoverProperty,
  } = usePropertyMapContext();

  const handleClick = () => {
    if (property.isActive) {
      deselectProperty(property.id);
    } else {
      selectProperty(property.id, PropertyMapEventSource.Map);
    }
  };

  const highlightColor = property.highlightColor || "gray.200";

  return (
    <MapMarker
      color={highlightColor}
      isActive={property.isActive}
      isHovered={property.isHovered}
      hasData={getHasData(property, insight)}
      latitude={property.latitude}
      longitude={property.longitude}
      onClick={() => handleClick()}
      onHoverStart={() => hoverProperty(property.id)}
      onHoverEnd={() => unhoverProperty(property.id)}
      popover={<PropertyMapPopover property={property} />}
    >
      <PropertyIcon type={property.type} size={25} color={getIconColor(highlightColor)} />
    </MapMarker>
  );
}

const PropertyMapPopover = ({ property }: ProperyMapMarkerProps) => {
  const { insight } = usePropertyMapContext();
  return (
    <Flex flexDir="column" alignItems="center" rowGap={1}>
      <PropertyAddressDisplay isLink={property.isActive} property={property} alignItems="center" />
      <InsightDisplay property={property} selectedInsight={insight} />
    </Flex>
  );
};

// This function is used to determine the color of the icon based on the background color, black if the
// background is bright, white if the background is dark
function getIconColor(color: string) {
  let r = 0,
    g = 0,
    b = 0,
    a = 0;

  if (color.startsWith("#")) {
    const withoutHash = color.slice(1);

    r = parseInt(withoutHash.slice(0, 2), 16);
    g = parseInt(withoutHash.slice(2, 4), 16);
    b = parseInt(withoutHash.slice(4, 6), 16);
    a = withoutHash.length === 8 ? parseInt(withoutHash.slice(6, 8), 16) : 255;
  } else if (color.startsWith("rgb(")) {
    const [rStr, gStr, bStr] = color.slice(4, -1).split(",");
    r = parseInt(rStr);
    g = parseInt(gStr);
    b = parseInt(bStr);
    a = 255;
  } else if (color.startsWith("rgba(")) {
    const [rStr, gStr, bStr, aStr] = color.slice(5, -1).split(",");
    r = parseInt(rStr);
    g = parseInt(gStr);
    b = parseInt(bStr);
    a = parseInt(aStr);
  } else {
    return "#000000";
  }

  const brightness = r * 0.299 + g * 0.587 + b * 0.114 + (255 - a);

  return brightness > 186 ? "#000000" : "#FFFFFF";
}

const getHasData = (
  { environmentalInsights, energyInsights }: PropertyMapPropertyData,
  selectedInsight: PortfolioInsight
) => {
  switch (selectedInsight) {
    case PortfolioInsight.Energy:
      return !!energyInsights?.totalEnergyUsageKwh;
    case PortfolioInsight.HealthScore:
      return environmentalInsights?.healthScore !== undefined;
    case PortfolioInsight.MouldRisk:
      return environmentalInsights?.mouldRiskPercentages !== undefined;
    case PortfolioInsight.Productivity:
      return environmentalInsights?.productivityPercentage !== undefined;
    case PortfolioInsight.AirborneIndex:
      return environmentalInsights?.airborneIndexPercentages !== undefined;
    case PortfolioInsight.Comfort:
      return environmentalInsights?.comfortIndexPercentages !== undefined;
    default:
      return false;
  }
};
