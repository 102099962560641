import { createContext, useMemo, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { OrganisationInsights, PeriodInsights } from "../../../lib/api/types/Insights";
import { OrganisationEnergyReadings } from "../../../lib/api/types/EnergyInsights";
import { CircuitInsights } from "../../property-graph/GraphUtils";
import { PortfolioInsight } from "../../portfolio-dashboard/data/types";
import { getPropertyHighlightColor } from "./helpers";
import { Property } from "../../../lib/api/types/Property";

export enum PropertyMapEventSource {
  Map = "MAP",
  Sidebar = "SIDEBAR",
}

export enum PropertyMapEvent {
  SelectedOnMap = "SELECTED_ON_MAP",
  SelectedOnSidebar = "SELECTED_ON_SIDEBAR",
}

export interface PropertyMapPropertyData extends Property {
  isActive?: boolean;
  isHovered?: boolean;
  environmentalInsights?: PeriodInsights;
  energyInsights?: CircuitInsights;
  highlightColor?: string;
}

interface State {
  properties: PropertyMapPropertyData[];
  insight: PortfolioInsight;
  organisationEnergyInsights: OrganisationEnergyReadings;
  organisationEnvironmentalInsights: OrganisationInsights;
  eventHandlers: Partial<Record<PropertyMapEvent, ((propertyId: string) => void)[]>>;
}

interface PropertyMapContextValue extends State {
  insight: PortfolioInsight;
  properties: PropertyMapPropertyData[];
  organisationEnergyInsights: OrganisationEnergyReadings;
  organisationEnvironmentalInsights: OrganisationInsights;
  hoverProperty: (propertyId: string) => void;
  unhoverProperty: (propertyId: string) => void;
  selectProperty: (propertyId: string, source: PropertyMapEventSource) => void;
  deselectProperty: (propertyId: string) => void;
  setInsight: (insight: PortfolioInsight) => void;
  subscribe: (event: PropertyMapEvent, handler: (propertyId: string) => void) => void;
  unsubscribe: (event: PropertyMapEvent, handler: (propertyId: string) => void) => void;
}

interface DialogProviderProps {
  children: ReactNode;
  properties: Property[];
  environmentalInsights?: OrganisationInsights;
  energyInsights?: OrganisationEnergyReadings;
}

type ActionType =
  | "HOVER_PROPERTY"
  | "UNHOVER_PROPERTY"
  | "SELECT_PROPERTY"
  | "DESELECT_PROPERTY"
  | "SET_INSIGHT"
  | "SUBSCRIBE"
  | "UNSUBSCRIBE";
interface Action {
  type: ActionType;
  payload?: {
    propertyId?: string;
    source?: PropertyMapEventSource;
    insight?: PortfolioInsight;
    event?: PropertyMapEvent;
    handler?: (propertyId: string) => void;
  };
}

const initialState: State = {
  properties: [] as PropertyMapPropertyData[],
  insight: PortfolioInsight.Energy,
  organisationEnergyInsights: {} as OrganisationEnergyReadings,
  organisationEnvironmentalInsights: {} as OrganisationInsights,
  eventHandlers: {} as Partial<Record<PropertyMapEvent, ((propertyId: string) => void)[]>>,
};

const updateProperty = (
  properties: PropertyMapPropertyData[],
  propertyId: string,
  change: Partial<PropertyMapPropertyData>
) => {
  const index = properties.findIndex((property) => property.id === propertyId);
  const updatedProperty = { ...properties[index], ...change };
  return [...properties.slice(0, index), updatedProperty, ...properties.slice(index + 1)];
};

const handlers: Record<ActionType, (state: State, action: Action) => State> = {
  HOVER_PROPERTY: (state: State, action: Action): State => {
    if (action.payload?.propertyId) {
      const properties = updateProperty(state.properties, action.payload.propertyId, { isHovered: true });
      return {
        ...state,
        properties,
      };
    }
    return state;
  },
  UNHOVER_PROPERTY: (state: State, action: Action): State => {
    if (action.payload?.propertyId) {
      const properties = updateProperty(state.properties, action.payload.propertyId, { isHovered: false });
      return {
        ...state,
        properties,
      };
    }
    return state;
  },
  SELECT_PROPERTY: (state: State, action: Action): State => {
    const { propertyId, source } = action.payload || {};
    if (propertyId) {
      let properties = state.properties;

      // If another property is selected, clear that selection
      const currentlySelected = state.properties.find((property) => property.isActive);
      if (currentlySelected) {
        if (currentlySelected.id === propertyId) {
          // This property's already selected, bomb out
          return state;
        }

        properties = updateProperty(properties, currentlySelected.id, { isActive: false });
      }

      properties = updateProperty(properties, propertyId, { isActive: true });

      // Notify subscribers
      if (source) {
        switch (source) {
          case PropertyMapEventSource.Map:
            state.eventHandlers[PropertyMapEvent.SelectedOnMap]?.forEach((handler) => handler(propertyId));
            break;
          case PropertyMapEventSource.Sidebar:
            state.eventHandlers[PropertyMapEvent.SelectedOnSidebar]?.forEach((handler) =>
              handler(propertyId)
            );
            break;
        }
      }

      return {
        ...state,
        properties,
      };
    }
    return state;
  },
  DESELECT_PROPERTY: (state: State, action: Action): State => {
    if (action.payload?.propertyId) {
      const properties = updateProperty(state.properties, action.payload.propertyId, { isActive: false });
      return {
        ...state,
        properties,
      };
    }
    return state;
  },
  SET_INSIGHT: (state: State, action: Action): State => {
    const insight = action.payload?.insight;
    if (insight) {
      // update property colours
      const properties = state.properties.map((property) => ({
        ...property,
        highlightColor: getPropertyHighlightColor(property, insight, state.organisationEnergyInsights),
      }));
      return {
        ...state,
        insight,
        properties,
      };
    }
    return state;
  },
  SUBSCRIBE: (state: State, action: Action): State => {
    const { event, handler } = action.payload || {};
    if (event && handler) {
      const handlers = state.eventHandlers[event] || [];
      handlers.push(handler);
      return {
        ...state,
        eventHandlers: {
          ...state.eventHandlers,
          [event]: handlers,
        },
      };
    }
    return state;
  },
  UNSUBSCRIBE: (state: State, action: Action): State => {
    const { event, handler } = action.payload || {};
    if (event && handler) {
      const eventHandlers = { ...state.eventHandlers };
      eventHandlers[event] = eventHandlers[event]?.filter((handler) => handler !== handler);

      return {
        ...state,
        eventHandlers,
      };
    }
    return state;
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const PropertyMapContext = createContext<PropertyMapContextValue>({
  ...initialState,
  hoverProperty: () => {},
  unhoverProperty: () => {},
  selectProperty: () => {},
  deselectProperty: () => {},
  setInsight: () => {},
  subscribe: () => {},
  unsubscribe: () => {},
});

export const PropertyMapDataProvider: FC<DialogProviderProps> = ({
  children,
  properties,
  energyInsights,
  environmentalInsights,
}) => {
  const combinedProperties = useMemo(
    () =>
      properties.map((property) => {
        const collated = {
          ...property,
          energyInsights: energyInsights?.properties.find((p) => p.id === property.id)?.insights,
          environmentalInsights: environmentalInsights?.properties.find((p) => p.propertyId === property.id)
            ?.insights,
        };
        return {
          ...collated,
          highlightColor: getPropertyHighlightColor(
            collated,
            initialState.insight,
            energyInsights || ({} as OrganisationEnergyReadings)
          ),
        };
      }),
    [properties, energyInsights, environmentalInsights]
  );

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    properties: combinedProperties,
    organisationEnergyInsights: energyInsights || initialState.organisationEnergyInsights,
    organisationEnvironmentalInsights:
      environmentalInsights || initialState.organisationEnvironmentalInsights,
  });

  const hoverProperty = (propertyId: string) => {
    dispatch({ type: "HOVER_PROPERTY", payload: { propertyId } });
  };

  const unhoverProperty = (propertyId: string) => {
    dispatch({ type: "UNHOVER_PROPERTY", payload: { propertyId } });
  };

  const selectProperty = (propertyId: string, source: PropertyMapEventSource) => {
    dispatch({ type: "SELECT_PROPERTY", payload: { propertyId, source } });
  };

  const deselectProperty = (propertyId: string) => {
    dispatch({ type: "DESELECT_PROPERTY", payload: { propertyId } });
  };

  const setInsight = (insight: PortfolioInsight) => {
    dispatch({ type: "SET_INSIGHT", payload: { insight } });
  };

  const subscribe = (event: PropertyMapEvent, handler: (propertyId: string) => void) => {
    dispatch({ type: "SUBSCRIBE", payload: { event, handler } });
  };

  const unsubscribe = (event: PropertyMapEvent, handler: (propertyId: string) => void) => {
    dispatch({ type: "UNSUBSCRIBE", payload: { event, handler } });
  };

  return (
    <PropertyMapContext.Provider
      value={{
        ...state,
        hoverProperty,
        unhoverProperty,
        selectProperty,
        deselectProperty,
        setInsight,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </PropertyMapContext.Provider>
  );
};

export default PropertyMapDataProvider;
