import { Flex, Text } from "@chakra-ui/react";
import AutoCompleteInput from "../autocomplete-input/AutoCompleteInput";
import { PortfolioInsight, PortfolioInsightIcons, PortfolioInsightLabels } from "./data/types";

interface DashboardInsightSelectorProps {
  insight: PortfolioInsight;
  onInsightSelected: (insight: PortfolioInsight) => void;
}

export default function DashboardInsightSelector({ insight, onInsightSelected }: DashboardInsightSelectorProps) {
  return (
    <Flex justifyContent="space-between" px={2} pt={5} alignItems="center" gap={3}>
      <Text>Display: </Text>
      <AutoCompleteInput
        width="200px"
        dropdownAsOverlay
        items={Object.values(PortfolioInsight).map((insight) => ({
          value: insight,
          label: PortfolioInsightLabels[insight],
          icon: PortfolioInsightIcons[insight]
        }))}
        placeholder="Choose..."
        value={""}
        onChange={() => { }}
        onSelectedItem={(item) => {
          onInsightSelected(item.value as PortfolioInsight);
        }}
        selectedItemValue={insight}
        renderSelectedItem
        disableTextInput
        renderItem={(item, collapsed: boolean) => (
          <Flex alignItems="center">
            {item.icon}
            <Flex ml={2}>{item.label}</Flex>
          </Flex>
        )}
      />
    </Flex>
  )
}