/* eslint-disable no-case-declarations */
import { OrganisationEnergyReadings } from "../../../lib/api/types/EnergyInsights";
import {
  AirborneIndexPercentages,
  MouldRiskCategory,
  MouldRiskPercentages,
} from "../../../lib/api/types/Insights";
import { AirborneRisk } from "../../../lib/api/types/InstallationLocationStatistics";
import { getAirborneRiskColor } from "../../airborne-index-chart";
import { getMouldRiskColor } from "../../mould-risk-chart/MouldRiskChart";
import { getHealthScoreColor } from "../../overview-tiles/HealthScore";
import { PortfolioInsight } from "../../portfolio-dashboard/data/types";
import { LatLng, MapPOI } from "./map-types";
import { PropertyMapPropertyData } from "./PropertyMapContext";

const DEFAULT_POSITION = { lat: -36.85089461227217, lng: 174.7645299276184 };

// Calculates the center of the map based on the average lat/long of the points
export const calculateMapCenter = (points: MapPOI[]): LatLng => {
  if (points.length === 0) {
    return DEFAULT_POSITION;
  }

  const latSum = points.reduce((acc, point) => acc + point.latitude, 0);
  const lngSum = points.reduce((acc, point) => acc + point.longitude, 0);

  return { lat: latSum / points.length, lng: lngSum / points.length };
};

// Calculates an initial zoom to display as many points as possible
export const calculateMapInitialZoom = (points: MapPOI[]): number => {
  if (points.length < 2) {
    return 10;
  }

  const latitudes = points.map((point) => point.latitude);
  const longitudes = points.map((point) => point.longitude);

  const maxLat = Math.max(...latitudes);
  const minLat = Math.min(...latitudes);
  const maxLng = Math.max(...longitudes);
  const minLng = Math.min(...longitudes);

  const latDiff = maxLat - minLat;
  const lngDiff = maxLng - minLng;

  const latZoom = Math.floor(Math.log2(1000 / latDiff));
  const lngZoom = Math.floor(Math.log2(1000 / lngDiff));

  return Math.min(latZoom, lngZoom);
};

export const getPropertyHighlightColor = (
  property: PropertyMapPropertyData,
  insight: PortfolioInsight,
  organisationEnergyInsights: OrganisationEnergyReadings
) => {
  let highlightColor = "green.400";
  switch (insight) {
    case PortfolioInsight.Energy:
      if (!property.energyInsights?.totalEnergyUsageKwh) {
        highlightColor = "gray.200";
        break;
      }

      const energyUsages = (
        organisationEnergyInsights?.properties.map((p) => p.insights?.totalEnergyUsageKwh) || []
      ).filter((v) => !!v);
      const minEnergyUsage = Math.min(...energyUsages);
      const maxEnergyUsage = Math.max(...energyUsages);
      const energyUsage = property.energyInsights.totalEnergyUsageKwh;
      const averageEnergyUsage = organisationEnergyInsights?.insights.averageEnergyUsageKwh || 0;

      const distanceFromAverage = Math.abs(energyUsage - averageEnergyUsage);
      const percentageFromAverage =
        distanceFromAverage /
        (energyUsage > averageEnergyUsage
          ? maxEnergyUsage - averageEnergyUsage
          : averageEnergyUsage - minEnergyUsage);

      if (energyUsage === averageEnergyUsage) {
        return `rgb(50, 50, 255)`;
      } else if (energyUsage <= averageEnergyUsage) {
        // Interpolate between green and blue
        const green = Math.min(Math.max(150, percentageFromAverage * 255), 255);
        const blue = Math.min(Math.max(50, 255 - percentageFromAverage * 255), 255);
        highlightColor = `rgb(50, ${green}, ${blue})`;
      } else {
        // Interpolate between blue and red
        const blue = Math.min(Math.max(50, 255 - percentageFromAverage * 255), 255);
        const red = Math.min(Math.max(50, percentageFromAverage * 512), 255);
        highlightColor = `rgb(${red}, ${percentageFromAverage < 0.5 ? 150 : 50}, ${blue})`;
      }

      break;
    case PortfolioInsight.HealthScore:
      if (property.environmentalInsights?.healthScore === undefined) {
        highlightColor = "gray.200";
        break;
      }
      highlightColor = getHealthScoreColor(property.environmentalInsights.healthScore);
      break;
    case PortfolioInsight.MouldRisk:
      const worstMouldRisk = getMaxMouldRiskCategory(property.environmentalInsights?.mouldRiskPercentages);
      highlightColor = worstMouldRisk ? getMouldRiskColor(worstMouldRisk) : "gray.200";

      break;
    case PortfolioInsight.Productivity:
      if (property.environmentalInsights?.productivityPercentage === undefined) {
        highlightColor = "gray.200";
        break;
      }

      if (property.environmentalInsights?.productivityPercentage > 0.95) {
        highlightColor = "green.400";
      } else if (property.environmentalInsights?.productivityPercentage > 0.9) {
        highlightColor = "#8DA94E";
      } else if (property.environmentalInsights?.productivityPercentage > 0.8) {
        highlightColor = "yellow.400";
      } else if (property.environmentalInsights?.productivityPercentage > 0.7) {
        highlightColor = "orange.400";
      } else if (property.environmentalInsights?.productivityPercentage > 0.6) {
        highlightColor = "red.400";
      }
      break;
    case PortfolioInsight.AirborneIndex:
      const worstAirborneRisk = getMaxAirborneRiskCategory(
        property.environmentalInsights?.mouldRiskPercentages
      );
      highlightColor = worstAirborneRisk ? getAirborneRiskColor(worstAirborneRisk) : "gray.200";
      break;

    case PortfolioInsight.Comfort:
      if (property.environmentalInsights?.comfortIndexPercentages === undefined) {
        highlightColor = "gray.200";
        break;
      }

      const perfectComfort = property.environmentalInsights?.comfortIndexPercentages.perfectComfort;
      if (perfectComfort > 0.8) {
        highlightColor = "green.400";
      } else if (perfectComfort > 0.6) {
        highlightColor = "yellow.400";
      } else if (perfectComfort > 0.4) {
        highlightColor = "orange.400";
      } else {
        highlightColor = "red.400";
      }

      break;
  }

  return highlightColor;
};

const getMaxMouldRiskCategory = (mouldRiskPercentages?: MouldRiskPercentages) => {
  if (mouldRiskPercentages?.veryHigh) return MouldRiskCategory.VeryHigh;
  if (mouldRiskPercentages?.high) return MouldRiskCategory.High;
  if (mouldRiskPercentages?.mid) return MouldRiskCategory.Mid;
  if (mouldRiskPercentages?.low) return MouldRiskCategory.Low;
  if (mouldRiskPercentages?.veryLow) return MouldRiskCategory.VeryLow;

  return;
};

const getMaxAirborneRiskCategory = (mouldRiskPercentages?: AirborneIndexPercentages) => {
  if (mouldRiskPercentages?.high) return AirborneRisk.HIGH;
  if (mouldRiskPercentages?.mid) return AirborneRisk.MEDIUM;
  if (mouldRiskPercentages !== undefined) return AirborneRisk.LOW;

  return;
};
