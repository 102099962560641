import { Box, Flex, Grid, HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { CircuitNodeWithUsage } from "../../lib/api/types/CircuitNode";
import { OrganisationEnergyReadings } from "../../lib/api/types/EnergyInsights";
import { OrganisationInsights } from "../../lib/api/types/Insights";
import { Property } from "../../lib/api/types/Property";
import AssetTagEnergyTile from "./AssetTagEnergyTile";
import DailyAverageTile from "./DailyAverageTile";
import DashboardInsightSelector from "./DashboardInsightSelector";
import DashboardPropertyList from "./DashboardPropertyList";
import { PortfolioInsight } from "./data/types";
import PortfolioCard from "./PortfolioCard";
import TotalEnergyTile from "./TotalEnergyTile";

interface PortfolioDashboardProps {
  properties: Property[];
  environmentalInsights?: OrganisationInsights;
  energyInsights?: OrganisationEnergyReadings;
  circuitTrees?: CircuitNodeWithUsage[];
}

export default function PortfolioDashboard({
  properties,
  environmentalInsights,
  energyInsights,
  circuitTrees,
}: PortfolioDashboardProps) {
  const [insight, setInsight] = useState<PortfolioInsight>(PortfolioInsight.Energy);
  const [tag, setTag] = useState<string | undefined>();
  return (
    <Flex flexDir="column" m={5} bgColor="white" p={5} w="100%" minW="900px">
      <HStack mt={-5}>
        <DashboardInsightSelector insight={insight} onInsightSelected={setInsight} />
      </HStack>

      <PortfolioDashboardTiles
        properties={properties}
        energyInsights={energyInsights}
        circuitTrees={circuitTrees}
        selectedInsight={insight}
        selectedTag={tag}
      />

      <Flex mt={8}>
        <DashboardPropertyList
          properties={properties}
          energyInsights={energyInsights}
          environmentalInsights={environmentalInsights}
          circuitTrees={circuitTrees}
          selectedInsight={insight}
          selectedTag={tag}
          onTagSelected={setTag}
        />
      </Flex>
    </Flex>
  );
}

interface PortfolioDashboardTilesProps {
  properties: Property[];
  energyInsights?: OrganisationEnergyReadings;
  circuitTrees?: CircuitNodeWithUsage[];
  selectedInsight: PortfolioInsight;
  selectedTag?: string;
}

function PortfolioDashboardTiles({
  properties,
  energyInsights,
  circuitTrees,
  selectedInsight,
  selectedTag,
}: PortfolioDashboardTilesProps) {
  switch (selectedInsight) {
    case PortfolioInsight.Energy:
      return (
        <Grid autoColumns="minmax(0, 1fr)" autoFlow="column" gap={2} minHeight={250}>
          <TotalEnergyTile insights={energyInsights} />
          <AssetTagEnergyTile nodeTrees={circuitTrees} />
          <DailyAverageTile properties={properties} insights={energyInsights} />
        </Grid>
      );
    default:
      return null;
  }
}
