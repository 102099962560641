import { Box, Flex, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import usePropertyMapContext from "../data/usePropertyMapContext";
import PropertyMapSidebarCard from "./PropertyMapSidebarCard";
import { sortProperties } from "../data/sort";
import DashboardInsightSelector from "../../portfolio-dashboard/DashboardInsightSelector";

interface PropertyMapSidebarProps {
}

export default function PropertyMapSidebar(props: PropertyMapSidebarProps) {
  const { insight, properties, setInsight } = usePropertyMapContext();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const sortedProperties = sortProperties(properties, sortDirection, insight);

  return (
    <Flex w={280} h="100%" flexDir="column">
      <DashboardInsightSelector insight={insight} onInsightSelected={setInsight} />
      <Flex p={2} justifyContent="flex-end">
        <IconButton bg="white" aria-label="Sort" icon={sortDirection === "asc" ? <BiSortUp /> : <BiSortDown />} onClick={() => setSortDirection(sortDirection === "asc" ? "desc" : "asc")} />
      </Flex>
      <Box w="100%" h="100%" overflowY="scroll" px={1}>
        <Flex direction="column" rowGap={1}>
        {sortedProperties.map((property) => (
          <PropertyMapSidebarCard
            key={`sidebar-card-${property.id}`}
            property={property}
            selectedInsight={insight}
          />
        ))}
      </Flex>
      </Box>
    </Flex >
  );
}