/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import * as React from "react";
import { Flex, Box, HStack, Text, Skeleton } from "@chakra-ui/react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  InitialTableState,
  FilterFn,
  ColumnDef,
} from "@tanstack/react-table";
import { DataTable } from "./DataTable";
import { SearchFilter } from "./SearchFilter";
import { isArray } from "lodash";

// Custom search filter to handle arrays or strings of arrays
const containsFilter: FilterFn<any> = (row, columnId, filter) => {
  const value = row.getValue(columnId);

  if (typeof value === "string") {
    return value.toLowerCase().includes(filter.toLowerCase());
  }

  if (isArray(value)) {
    return Boolean(
      value.find((item) => typeof item === "string" && item.toLowerCase().includes(filter.toLowerCase()))
    );
  }

  return false;
};

interface DataTableContainerProps {
  columns: ColumnDef<any, any>[];
  data: any[];
  defaultSort?: { accessor: string; desc: boolean };
  showFilter: boolean;
  filterPlaceholderText?: string;
  pageTitle: string;
  isLoading?: boolean;
  headerPanel?: JSX.Element;
  children?: JSX.Element;
  actions?: JSX.Element;
  hiddenColumns?: string[];
  fontSize?: string;
  hasColoredHeader?: boolean;
  hasBorderShadow?: boolean;
  showFooter?: boolean;
}

function DataTableContainerP({
  data,
  columns,
  defaultSort,
  showFilter,
  filterPlaceholderText,
  pageTitle,
  isLoading,
  headerPanel,
  children,
  actions,
  hiddenColumns,
  fontSize,
  hasColoredHeader,
  hasBorderShadow,
  showFooter,
}: DataTableContainerProps) {
  let initialState: InitialTableState = {};
  if (defaultSort) {
    initialState = {
      sorting: [
        {
          id: defaultSort.accessor,
          desc: defaultSort.desc,
        },
      ],
    };
  }

  if (hiddenColumns) {
    initialState.columnVisibility = {};
    hiddenColumns.forEach((c) => (initialState.columnVisibility![c] = false));
  }

  const tableData = React.useMemo(() => (isLoading ? Array(30).fill({}) : data), [isLoading, data]);
  const tableColumns = React.useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton height="14px" />,
          }))
        : columns,
    [isLoading, columns]
  );

  const dt = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: containsFilter,
    initialState,
    enableGlobalFilter: true,
    enableSorting: true,

    // This is forced here because otherwise columns with an array of string for a value will
    // be excluded from the search filter for some unknown reason
    getColumnCanGlobalFilter: (column) => column.columnDef.enableGlobalFilter !== false,
  });

  return (
    <>
      <HStack justifyContent="space-between" mb={6}>
        {pageTitle && (
          <Text color="black" fontWeight={700} fontSize={fontSize}>
            {pageTitle}
          </Text>
        )}

        <Flex justifyContent={"end"} mb={5}>
          {Boolean(showFilter) && (
            <Box mr={3} width={200}>
              <SearchFilter
                globalFiltertext={""}
                setGlobalFilterText={dt.setGlobalFilter}
                filterPlaceholderText={filterPlaceholderText}
              />
            </Box>
          )}
          {actions}
        </Flex>
      </HStack>
      {headerPanel}
      {children}
      <Box borderRadius="8px">
        <DataTable
          showFooter={showFooter}
          dt={dt}
          hasColoredHeader={hasColoredHeader}
          hasBorderShadow={hasBorderShadow}
        />
      </Box>
    </>
  );
}

export const DataTableContainer = React.memo(DataTableContainerP);
