import { NextPage } from "next";
import React, { useEffect, useState } from "react";

import {
  useGetCircuitNodeTreesWithUsageForProperties,
  useGetProperties,
} from "../../lib/api/properties/hooks";
import { Box, Flex, Heading, HStack } from "@chakra-ui/react";
import Head from "next/head";
import { useAuth } from "../../lib/auth/useAuth";
import { useRouter } from "next/router";
import { NewUserModal } from "../../components/new-user/NewUserModal";
import { useGetUser } from "../../lib/api/users/hooks";
import moment from "moment";
import { NewUserMobileModal } from "../../components/new-user/NewUserMobileModal";
import { useGetOrganisationInsights } from "../../lib/api/insights/hooks";
import DateRangeControls, { MomentDateRange } from "../../components/property/DateRangeControls";
import { useGetOrganisationPowerReadings } from "../../lib/api/readings/hooks";
import PropertyMap from "../../components/property-map/PropertyMap";
import { ToggleButtons } from "../../components/button/ToggleButtons";
import PortfolioDashboard from "../../components/portfolio-dashboard/PortfolioDashboard";
import { ProgressLoader } from "../../components/loading/ProgressLoader";

const NewUserContainer = ({ onClose }: { onClose: () => void }) => {
  const isMobile = window.innerWidth < 768;
  return (
    <div>{isMobile ? <NewUserMobileModal onClose={onClose} /> : <NewUserModal onClose={onClose} />}</div>
  );
};

const DEFAULT_DAY_RANGE = 7;

const PortfolioDashboardPage: NextPage = () => {
  const router = useRouter();
  const { user } = useAuth();

  const [dateRange, setDateRange] = useState<MomentDateRange>({
    startDate: moment().subtract(DEFAULT_DAY_RANGE, "days").startOf("hour"),
    endDate: moment().endOf("hour"),
  });

  const { data: properties = [], isLoading: propertiesLoading } = useGetProperties();
  const {
    organisationInsights,
    isLoading: insightsLoading,
    error: OrganisationError,
  } = useGetOrganisationInsights({
    startDate: dateRange.startDate.toISOString(),
    endDate: dateRange.endDate.toISOString(),
  });

  const { organisationPower, isLoading: powerLoading, error: powerError } = useGetOrganisationPowerReadings({
    fromTimestamp: dateRange.startDate.toISOString(),
    toTimestamp: dateRange.endDate.toISOString(),
    resolution: "DAILY",
  });

  const {
    circuitTrees: circuitTreesByProperty = [],
    isLoading: circuitTreesLoading,
    errors: circuitTreeErrors,
  } = useGetCircuitNodeTreesWithUsageForProperties(
    properties.map((p) => p.id),
    {
      fromTimestamp: dateRange.startDate.toISOString(),
      toTimestamp: dateRange.endDate.toISOString(),
    }
  );

  const circuitTrees = circuitTreesByProperty.flat();

  const [currentView, setCurrentView] = useState<"map" | "list">("map");

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const { user: authUser } = useGetUser(user?.id as string);

  const loading = propertiesLoading || insightsLoading || powerLoading || circuitTreesLoading;
  const loadingBools = [propertiesLoading, insightsLoading, powerLoading, circuitTreesLoading];

  useEffect(() => {
    if (authUser) {
      // Get local storage value
      const hideWelcomeModal = localStorage.getItem("hideWelcomeModal" + authUser.id);
      if (hideWelcomeModal) {
        return;
      }
      const momentDate1 = moment(authUser.createdAt);
      const momentDate2 = moment();

      // Calculate the difference in days between the two dates
      const diffInDays = momentDate2.diff(momentDate1, "days");

      if (diffInDays < 7) {
        setShowWelcomeModal(true);
      }
    }
  }, [authUser]);

  const handleCloseWelcomeBanner = () => {
    if (authUser) {
      localStorage.setItem("hideWelcomeModal" + authUser.id, "true");
    }
    setShowWelcomeModal(false);
  };

  return (
    <>
      <Head>
        <title>Tether | Portfolio Dashboard</title>
      </Head>

      {showWelcomeModal && <NewUserContainer onClose={handleCloseWelcomeBanner} />}

      <Flex flexDir="column" h="100vh">
        <Box display="block">
          <Flex w="100%" justifyContent="space-between">
            <Heading size="md" p={4}>
              Portfolio Dashboard
            </Heading>
            <HStack pr={5}>
              <DateRangeControls
                setNewDateRange={setDateRange}
                selectedDateRange={dateRange}
                maxSelectedDays={90}
              />
              <ToggleButtons
                width={65}
                height={8}
                background="white"
                buttons={[
                  { label: "Map", value: "map" },
                  { label: "List", value: "list" },
                ]}
                selectedValue={currentView}
                onSelect={(value: "map" | "list") => setCurrentView(value)}
              />
            </HStack>
          </Flex>
        </Box>

        <Flex>
          {loading && (
            <Flex minH="calc(100vh - 54px)" alignItems="space-around" w="100%">
              <ProgressLoader
                max={loadingBools.length}
                value={loadingBools.reduce((acc, curr) => (curr ? acc : acc + 1), 0)}
              />
            </Flex>
          )}
          {!loading && currentView === "list" && (
            <PortfolioDashboard
              properties={properties}
              environmentalInsights={organisationInsights}
              energyInsights={organisationPower}
              circuitTrees={circuitTrees}
            />
          )}

          {!loading && currentView === "map" && (
            <Flex h="calc(100vh - 54px)" overflow="hidden" alignItems="space-around" w="100%">
              <PropertyMap
                properties={properties}
                environmentalInsights={organisationInsights}
                energyInsights={organisationPower}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default PortfolioDashboardPage;
