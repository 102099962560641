import { Box } from "@chakra-ui/react";
import { utcToZonedTime } from "date-fns-tz";
import moment from "moment";
import { useMemo } from "react";
import { OrganisationEnergyReadings } from "../../lib/api/types/EnergyInsights";
import { DayOfWeek } from "../../lib/api/types/MonitoringHours";
import { Property } from "../../lib/api/types/Property";
import PortfolioCard from "./PortfolioCard";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import { startCase } from "lodash";

interface DailyAverageTileProps {
  properties: Property[];
  insights?: OrganisationEnergyReadings;
}

type DailyTotals = Record<DayOfWeek, number>;

export default function DailyAverageTile({ properties, insights }: DailyAverageTileProps) {
  const dailyTotals = useMemo(() => {
    Object.values(DayOfWeek).forEach((day) => {});

    // Timezones by property ID
    const timezones = properties.reduce(
      (acc, property) => ({ ...acc, [property.id]: property.timezone }),
      {} as Record<string, string>
    );

    // All readings with their respective timezones
    const allReadings = insights?.properties.flatMap((property) =>
      property.mainsCircuit?.readings.map((reading) => ({ reading, timezone: timezones[property.id] }))
    );

    let readingsByDay: number[][] = Array(7)
      .fill([])
      .map(() => []);

    allReadings?.forEach((readingWithTimezone) => {
      if (!readingWithTimezone) {
        return;
      }
      const { reading, timezone } = readingWithTimezone;
      const date = utcToZonedTime(reading.endTimestamp, timezone || moment.tz.guess());
      const day = date.getDay();
      readingsByDay[day].push(reading.totalkWh);
    });

    // Shuffle so Sunday is the last day instead of the first
    readingsByDay = [...readingsByDay.slice(1), readingsByDay[0]];

    // Map to data set with name of day and total
    return readingsByDay.map(
      (readings, index) => ({
        name: startCase(Object.values(DayOfWeek)[index]).slice(0, 3),
        y: Math.round(readings.reduce((acc, reading) => acc + reading, 0)),
      }),
      {} as DailyTotals
    );
  }, [properties, insights]);

  return (
    <PortfolioCard title="Daily Energy Usage">
      <DailyAverageGraph data={dailyTotals} />
    </PortfolioCard>
  );
}

export const DailyAverageGraph = ({ data }: { data: { name: string; y: number }[] }) => {
  const graphOptions: Highcharts.Options = {
    chart: {
      type: "column",
      height: 190,
      width: 300,
    },
    navigator: { enabled: false },
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {},
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        format: " ",
      },
      gridLineWidth: 0,
      reversed: false,
    },
    series: [
      {
        name: "Energy Usage",
        type: "column",
        data,
      },
    ],
  };

  return (
    <Box height={150} mt={-53}>
      <HighchartsReact constructorType="chart" highcharts={Highcharts} options={graphOptions} />
    </Box>
  );
};
