import { Box, Flex } from "@chakra-ui/react";
import { APIProvider, useMap } from "@vis.gl/react-google-maps";
import React, { useEffect } from "react";
import { OrganisationEnergyReadings } from "../../lib/api/types/EnergyInsights";
import { OrganisationInsights } from "../../lib/api/types/Insights";
import { Property } from "../../lib/api/types/Property";
import { calculateMapCenter } from "./data/helpers";
import { PropertyMapDataProvider, PropertyMapEvent } from "./data/PropertyMapContext";
import usePropertyMapContext from "./data/usePropertyMapContext";
import PropertyMapMarker from "./map-marker/PropertyMapMarker";
import MapWithMarkers from "./MapWithMarkers";
import PropertyMapSidebar from "./sidebar/PropertyMapSidebar";

// Filters out properties without valid lat/longs
export const checkPropertyCoordinates = (property: Property): boolean => {
  if (!property.latitude || !property.longitude) {
    return false;
  }

  // There was a period where lat/longs were reversed. Quick and nasty hack to filter them out for now.
  if (property.addressCountry?.toLowerCase() === "new zealand" && property.longitude < 0) {
    return false;
  }

  return true;
};

interface PropertyMapProps {
  properties: Property[];
  environmentalInsights?: OrganisationInsights;
  energyInsights?: OrganisationEnergyReadings;
}

export default function PropertyMap({ properties, environmentalInsights, energyInsights }: PropertyMapProps) {
  return (
    <PropertyMapDataProvider
      properties={properties}
      environmentalInsights={environmentalInsights}
      energyInsights={energyInsights}
    >
      <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_KEY || ""}>
        <Box flex="1 0 auto" overflow="auto">
          <PropertyMapInner />
          <PropertyMapHandlers />
        </Box>
      </APIProvider>
    </PropertyMapDataProvider>
  );
}

const PropertyMapInner: React.FC = () => {
  const { properties } = usePropertyMapContext();
  const propertiesToShow = properties.filter(checkPropertyCoordinates);

  const mapPoints = propertiesToShow.map((p) => ({
    key: `map-point-${p.id}`,
    latitude: p.latitude,
    longitude: p.longitude,
    markerComponent: <PropertyMapMarker property={p} key={`marker-${p.id}`} />,
    popUpComponent: <Box>{p.address1}</Box>,
  }));

  return (
    <Flex position="relative" w="100%" h="100%">
      <Flex left={0} zIndex={1} bg="#fffd" px={4}>
        <PropertyMapSidebar />
      </Flex>
      <Box w="100%" h="100%">
        <MapWithMarkers points={mapPoints} center={calculateMapCenter(mapPoints)} />
      </Box>
    </Flex>
  );
};

// Creates a handler to track clicks on the sidebar and pan to the selected property
// Has to be a sibling the the component that creates the map to be able to access the map
const PropertyMapHandlers: React.FC = () => {
  const { properties, subscribe, unsubscribe } = usePropertyMapContext();
  const propertiesToShow = properties.filter(checkPropertyCoordinates);
  const map = useMap();

  useEffect(() => {
    const handler = (propertyId: string) => {
      const prop = propertiesToShow.find((p) => p.id === propertyId);
      if (prop && map) {
        // Shouldn't need both of these but it doesn't seem to work without both
        map.setCenter({ lat: prop.latitude, lng: prop.longitude });
        map.panTo({ lat: prop.latitude, lng: prop.longitude });
      }
    };

    subscribe(PropertyMapEvent.SelectedOnSidebar, handler);
    return () => unsubscribe(PropertyMapEvent.SelectedOnSidebar, handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  return <></>;
};
