import { Box, IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import { TbCurrentLocationOff } from "react-icons/tb";
import { Property } from "../../../lib/api/types/Property";
import GeoLocatorModal from "../../geo-locator/GeoLocatorModal";

interface MissingLocationIconProps {
  property: Property;
}

export default function MissingLocationIcon({ property }: MissingLocationIconProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Tooltip label="Location coordinates are missing for this property, click here to set the location of this property">
        <IconButton
          ml={2}
          minW={8}
          minH={8}
          h={8}
          bgColor="white"
          boxShadow="md"
          _hover={{
            opacity: 0.5,
          }}
          borderWidth="1px"
          aria-label="Add missing location data"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onOpen();
          }}
        >
          <Box color="gray.400">
            <TbCurrentLocationOff size={20} />
          </Box>
        </IconButton>
      </Tooltip>
      {isOpen && <GeoLocatorModal property={property} isOpen={isOpen} onClose={onClose} />}
    </>
  );
}
