import { useEffect, useState } from "react";
import { useUpdatePropertyMutation } from "../../lib/api/properties/hooks";
import { Property } from "../../lib/api/types/Property";
import useIPFind from "../../lib/hooks/useIPFind";
import { Loader } from "../loading/Loader";
import { DefaultModal } from "../modals/DefaultModal";
import { LatLng } from "../property-map/data/map-types";
import GeoLocator from "./GeoLocator";

interface GeoLocatorModalProps {
  property: Property;
  isOpen: boolean;
  onClose: () => void;
}

export const getIsPropertyLocationValid = (property: Property) => {
  return (
    property.latitude != undefined &&
    property.longitude != undefined &&
    property.latitude > -90 &&
    property.latitude < 90 &&
    property.longitude > -180 &&
    property.longitude < 180
  );
};

export default function GeoLocatorModal({ property, isOpen, onClose }: GeoLocatorModalProps) {
  const isPropertyLocationValid = getIsPropertyLocationValid(property);
  const { data: ipLocation, isLoading: ipFindLoading, error: ipFindError } = useIPFind({
    enabled: !isPropertyLocationValid,
  });
  const updatePropertyMutation = useUpdatePropertyMutation();
  const [position, setPosition] = useState<LatLng | undefined>(
    isPropertyLocationValid ? { lat: property.latitude, lng: property.longitude } : undefined
  );

  const waitForIPLocation = ipFindLoading && !property.latitude && !property.longitude;

  // When the IP location is found, set the position to the IP location (if property has no GPS coords)
  useEffect(() => {
    if (!ipFindLoading) {
      if ((ipLocation?.latitude && ipLocation?.longitude) || ipFindError) {
        setPosition({ lat: ipLocation?.latitude || -36.848461, lng: ipLocation?.longitude || 174.763336 });
      }
    }
  }, [ipLocation, ipFindLoading]);

  const handleSave = async () => {
    if (!position) return;

    try {
      await updatePropertyMutation.mutateAsync({
        input: {
          ...property,
          latitude: position.lat,
          longitude: position.lng,
        },
      });
      onClose();
    } catch (e) {
      // Handled in mutation
    }
  };

  return (
    <>
      {isOpen && (
        <DefaultModal
          title="Update Map Location"
          onClose={onClose}
          isOpen={isOpen}
          onConfirm={handleSave}
          confirmText="Save"
          confirmDisabled={waitForIPLocation}
        >
          {(waitForIPLocation || !position) && <Loader />}
          {!waitForIPLocation && !!position && (
            <GeoLocator
              property={property}
              position={position}
              setPosition={setPosition}
              defaultZoom={isPropertyLocationValid ? 15 : 10}
            />
          )}
        </DefaultModal>
      )}
    </>
  );
}
