import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { OrganisationEnergyReadings } from "../../lib/api/types/EnergyInsights";
import UnitDisplay from "../unit-display/UnitDisplay";
import PortfolioCard from "./PortfolioCard";

interface TotalEnergyTileProps {
  insights?: OrganisationEnergyReadings;
}

export default function TotalEnergyTile({ insights }: TotalEnergyTileProps) {
  return (
    <PortfolioCard title="Total Energy">
      <Flex
        flexDir="column"
        w="100%"
        sx={{ "& > :nth-child(odd)": { backgroundColor: "gray.50" } }}
        h="100%"
        justifyContent="center"
      >
        <Flex h="20%" alignItems={"center"} justifyContent={"center"} columnGap={2}>
          <Text>Energy Usage:</Text>
          <Text fontSize={"lg"} fontWeight={"bold"}>
            {insights?.insights.totalEnergyUsageKwh !== undefined ? (
              <UnitDisplay value={insights.insights.totalEnergyUsageKwh} unit={"kWh"} spacing={1} />
            ) : (
              "No data"
            )}
          </Text>
        </Flex>
        <Flex h="20%" alignItems={"center"} justifyContent={"center"} columnGap={2}>
          <Text>Energy Cost:</Text>
          <Text fontSize={"lg"} fontWeight={"bold"}>
            {insights?.insights.totalEnergyUsageKwh !== undefined ? (
              <UnitDisplay value={insights.insights.estimatedEnergyCost} prefix="$" />
            ) : (
              "No data"
            )}
          </Text>
        </Flex>
        <Flex h="20%" alignItems={"center"} justifyContent={"center"} columnGap={2}>
          <Text>CO₂ Emissions:</Text>
          <Text fontSize={"lg"} fontWeight={"bold"}>
            {insights?.insights.totalEnergyUsageKwh !== undefined ? (
              <UnitDisplay value={insights.insights.CO2ENumberOfTrees} unit="kg CO₂e" spacing={1} />
            ) : (
              "No data"
            )}
          </Text>
        </Flex>
      </Flex>
    </PortfolioCard>
  );
}
