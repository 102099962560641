import { Box, Center, CloseButton, Flex, HStack, Image, Link, Text } from "@chakra-ui/react";
import React from "react";

interface NewUserMobileModalProps {
  onClose: () => void;
}

export const NewUserMobileModal = ({ onClose }: NewUserMobileModalProps) => (
  <Flex
    flex={1}
    h={"100vh"}
    w="100%"
    alignItems={"center"}
    flexDirection="column"
    justifyContent={"space-between"}
    px={4}
    pt={4}
  >
    <Box alignSelf={"flex-end"}>
      <CloseButton onClick={onClose} color={"black"} variant={"ghost"} />
    </Box>
    <Text fontWeight="bold">WELCOME TO TETHER!</Text>
    <Text textAlign={"center"} fontWeight="bold" fontSize={30}>
      Download our app for the best mobile experience
    </Text>
    <Text mx={6} textAlign={"center"}>
      For an optimal mobile experience while monitoring your building on the move, our app is the solution -
      it&apos;s crafted for mobile use, unlike our browser platform.
    </Text>
    <HStack>
      <Link href="https://apps.apple.com/au/app/tether-mobile/id1391516440">
        <Image alt="app store icon" src="/assets/app-download-buttons 1@2x.png" height={31} width={94} />
      </Link>
      <Link href="https://play.google.com/store/apps/details?id=com.tether.installation&pli=1">
        <Image alt="playstore icon" src="/assets/google-playstore.png" style={{ height: 46, width: 100 }} />
      </Link>
    </HStack>
    <Image alt="mobile app" src={"/assets/property-dashboard.png"} height={360} width={252} />
  </Flex>
);
