import { Box, Flex } from "@chakra-ui/react";
import { AdvancedMarker, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import React, { ReactNode } from "react";
import { darken } from "@chakra-ui/theme-tools";
import theme from "../../global-styles/theme";

enum MarkerZIndex {
  NoData = 0,
  Normal = 1,
  Active = 3,
  Hover = 2,
}

const getZIndex = (hasData: boolean, isActive: boolean, isHovered: boolean) => {
  if (isActive) return MarkerZIndex.Active;
  if (isHovered) return MarkerZIndex.Hover;
  if (!hasData) return MarkerZIndex.NoData;
  return MarkerZIndex.Normal;
};

export interface MapMarkerProps {
  color: string;
  latitude: number;
  longitude: number;
  children: ReactNode;
  isActive?: boolean;
  isHovered?: boolean;
  hasData?: boolean;
  popover?: ReactNode;
  onClick: () => void;
  onHoverStart: () => void;
  onHoverEnd: () => void;
}

export default function MapMarker({
  color,
  children,
  isActive = false,
  isHovered = false,
  hasData = false,
  latitude,
  longitude,
  popover,
  onClick,
  onHoverStart,
  onHoverEnd,
}: MapMarkerProps) {
  const [markerRef, marker] = useAdvancedMarkerRef();
  return (
    <AdvancedMarker
      position={{ lat: latitude, lng: longitude }}
      zIndex={getZIndex(hasData, isActive, isHovered)}
      onClick={onClick}
      ref={markerRef}
    >
      {/* Custom popover */}
      {!!popover && !!isActive && (
        <Flex flexDir="column" alignItems="center">
          <Box bgColor="white" boxShadow="lg" p={2} borderRadius="md">
            {popover}
          </Box>
          <Box
            width="0"
            height="0"
            borderLeft="10px solid transparent"
            borderRight="10px solid transparent"
            borderTop="20px solid white"
          />
        </Flex>
      )}
      <Flex flexDir="column" alignItems="center" position="relative">
        {/* Slightly oversided marker for 'active' state */}
        {!!isActive && (
          <Flex position="absolute" top="-2px">
            <Flex
              mb={3}
              backgroundColor={darken(color, 20)(theme)}
              borderRadius={20}
              w={9}
              h={9}
              justifyContent="center"
              alignItems="center"
            ></Flex>
            <Box
              position="absolute"
              top="28px"
              left="3px"
              borderRadius={0}
              borderTopWidth={21}
              borderTopColor={darken(color, 20)(theme)}
              borderLeftWidth={15}
              borderLeftColor="transparent"
              borderRightWidth={15}
              borderRightColor="transparent"
              borderBottomWidth={21}
              borderBottomColor="transparent"
              w={0}
              h={0}
              justifyContent="center"
              alignItems="center"
            />
          </Flex>
        )}
        <Flex
          mb={3}
          backgroundColor={isHovered ? darken(color, 10)(theme) : color}
          borderRadius={20}
          w={8}
          h={8}
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          onMouseEnter={onHoverStart}
          onMouseLeave={onHoverEnd}
        >
          {children}
        </Flex>
        <Box
          position="absolute"
          top={6}
          borderRadius={0}
          borderTopWidth={20}
          borderTopColor={isHovered ? darken(color, 10)(theme) : color}
          borderLeftWidth={13}
          borderLeftColor="transparent"
          borderRightWidth={13}
          borderRightColor="transparent"
          borderBottomWidth={20}
          borderBottomColor="transparent"
          w={0}
          h={0}
          justifyContent="center"
          alignItems="center"
          onMouseEnter={onHoverStart}
          onMouseLeave={onHoverEnd}
        />
      </Flex>
    </AdvancedMarker>
  );
}
